@charset "UTF-8";

/* CSS Document */

/*------------------------------------------------------------------

Template Name:      Ophthalmology | Pathology Lab Medical Websites Templates
Version:            1.0.0
Date:               01/05/2017 
Author:             Jitu Chauhan

------------------------------------------------------------------
[CSS Table of contents]

1.  Body / Typography 
2.  Form Elements
3.  Buttons
4.  General / Elements
        4.1 Section space
        4.2 Margin space
        4.3 Padding space
        4.4 Background color
        4.5 Features
        4.6 Bullets 
        4.7 Call to actions
        4.8 Img hover effect
        4.9 Icon
        4.10 Paginations

        -----------------------
5. Header / Navigations
        5.1 Header Regular
        5.2 Header Transparent
        5.3 Navigation Regular
        5.4 Page Header

        -----------------------
6. Slider
        6.1 Slider
        -----------------------
7. Content
8. Footers
9. Template Pages
    9.1  About us
    9.2  Service
    9.3  Testimonial
    9.4  Team
    9.5  Pricing
    9.6  Location
    9.7  Contact us
    9.8  Faq
    ------------------------------------
10.Blog
    10.1 Blog Default
    10.2 Blog Single
    10.3 Add new if new update
    ------------------------------------
  
11.Sidebar
    11.1 Widget
    11.2 Sidenav
    ------------------------------------
12. Shortcodes
    12.2 Accordions
    --------------------------

13. Back To Top    

14. Media Queries

15. index
    15.1 index > service icon
    15.2 index > news
    15.3 index > change slider
    15.4 index > testimonials
    15.5 index > video
    15.6 index > contact
    15.7. Floating sns bar
    15.8. m-fixed-bar

16 Service
16.1 Service > table

17. News
18. Testimonials
19. Case
20. About
21. Contact
22. Search Result


-------------------------------------------------------------------*/

/*-----------------------------------
    1. Body / Typography 
-----------------------------------*/

body {
    font-family: 'Open Sans', "微軟正黑體", sans-serif;
    font-size: 16px;
    background-color: #fff;
    color: #6c676f;
    line-height: 26px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0px 0px 10px 0px;
    padding: 0;
    color: #3b383d;
    /*text-transform: uppercase;*/
    font-weight: 600;
    letter-spacing: 1px;
}

h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 18px;
    line-height: 30px;
}

h4 {
    font-size: 16px;
}

h5 {
}

a {
    color: #2463a7;
}

a:focus, a:hover {
    color: #2463a7;
    text-decoration: none;
}

p {
    margin-bottom: 20px;
}

p:last-child {
    margin-bottom: 0px;
}

ul, ol {
    margin-bottom: 0px;
}

ul li, ol li {
}

ul:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0px;
}

.section-title {
    margin-bottom: 60px;
}

.title {
    color: #3b383d;
}

.text-white {
    color: #fff;
}

.listnone {
    list-style: none;
    padding: 0px;
}

.required {
    color: red;
}

.text-default {
    color: #7d4c9e;
}

.alignleft {
    float: left;
    margin: 0px 30px 40px 0;
    border: 3px solid #fff;
}

.alignright {
    float: right;
    margin: 0px 0 20px 20px;
    border: 3px solid #fff;
}

a {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    outline: none;
}


.flexbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
    background-color: transparent;
}

.container div.container {
    padding: 0;
}

iframe {
    border: 0;
}

.flex-container {
    display: flex;
    align-items: end;
}

.flex-container img {
    margin-right: 10px;
}

.flex-container h2 {
    font-size: 17px;
    line-height: 35px;
    color: #3b383d;
}

.flex-container h2 span {
    color: #6c676f;
    font-size: 16px;
    line-height: 26px;
    display: block;
    margin-bottom: 10px;
    letter-spacing: normal;
    font-weight: normal;
}

/*-----------------------------------
    2. Form css
-----------------------------------*/
.form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dadedf;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    color: #5f5b62;
}

::selection {
    background: #ffecb3;
    color: #333;
}

.lead {
    color: #2c2530;
    font-weight: 600;
}

.input-group-addon {
    background-color: #7d4d98;
    color: #fff;
}

select.form-control {
    background-color: #fff;
    color: #5f5b62;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: 97% 50%;
    background-repeat: no-repeat;
    background-image: url(/images/down-arrow.png);
}

.default-skin .ui-widget-header {
    border: 1px solid #7d4d98;
    background: #7d4d98;
    color: #fff;
    font-weight: bold;
}

.default-skin .ui-state-highlight, .default-skin .ui-widget-content .ui-state-highlight,
.default-skin .ui-widget-header .ui-state-highlight {
    border: 1px solid #7d4d98;
    background: #7d4d98;
    color: #fff;
}

/*-----------------------------------
    3. Buttons
-----------------------------------*/
.btn {
    display: inline-block;
    padding: 12px 18px;
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.42857143;
    text-align: center;
    text-transform: uppercase;
    border-radius: 2px;
    letter-spacing: 1px;
    font-weight: 700;
}

.btn-default {
    color: #fff;
    background-color: #8f61a9;
    border-color: #8f61a9;
}

.btn-default:hover {
    color: #fff;
    background-color: #7d4d98;
    border-color: #7d4d98;
}

.btn-default.focus, .btn-default:focus {
    color: #fff;
    background-color: #7d4d98;
    border-color: #7d4d98;
}

.btn-primary {
    color: #fff;
    background-color: #c4c833;
    border-color: #c4c833;
}

.btn-primary:hover {
    color: #fff;
    background-color: #acb020;
    border-color: #acb020;
}

.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #acb020;
    border-color: #acb020;
}

.btn-secondary {
    color: #fff;
    background-color: #7c7b7d;
    border-color: #7c7b7d;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #57545a;
    border-color: #57545a;
}

.btn-secondary.focus, .btn-secondary:focus {
    color: #fff;
    background-color: #57545a;
    border-color: #57545a;
}

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.btn-lg {
    padding: 14px 26px;
    font-size: 18px;
    line-height: 1.3333333;
}

.btn-link {
    font-size: 14px;
    text-transform: uppercase;
    color: #7d4c9e;
    font-weight: 800;
}

.btn-link:hover {
    font-size: 14px;
    text-transform: uppercase;
    color: #3a383c;
    text-decoration: none;
}

/*-----------------------------------
    4. General / Elements
-----------------------------------*/
/*-----------------------
    4.1 Section space :
-------------------------*/

/*Note: Section space help to create top bottom space;*/

.space-xs-small {
    padding-top: 20px;
    padding-bottom: 20px;
}

.space-small {
    padding-top: 40px;
    padding-bottom: 40px;
}

.space-medium {
    padding-top: 80px;
    padding-bottom: 80px;
}

.space-large {
    padding-top: 100px;
    padding-bottom: 100px;
}

.space-ex-large {
    padding-top: 120px;
    padding-bottom: 120px;
}

/*----------------------
    4.2 Margin Space
 -----------------------*/
.mb0 {
    margin-bottom: 0px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb100 {
    margin-bottom: 100px;
}

.mt0 {
    margin-top: 0px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mt40 {
    margin-top: 40px;
}

.mt60 {
    margin-top: 60px;
}

.mt80 {
    margin-top: 80px;
}

.mt100 {
    margin-top: 100px;
}

/*----- less margin space from top --*/
.mt-20 {
    margin-top: -20px;
}

.mt-40 {
    margin-top: -40px;
}

.mt-60 {
    margin-top: -60px;
}

.mt-80 {
    margin-top: -80px;
}

/*-----------------------
    4.3 Padding space
-------------------------*/
.nopadding {
    padding: 0px;
}

.nopr {
    padding-right: 0px;
}

.nopl {
    padding-left: 0px;
}

.pinside10 {
    padding: 10px;
}

.pinside20 {
    padding: 20px;
}

.pinside30 {
    padding: 30px;
}

.pinside40 {
    padding: 40px;
}

.pinside50 {
    padding: 50px;
}

.pinside60 {
    padding: 60px;
}

.pinside80 {
    padding: 80px;
}

.pinside130 {
    padding: 130px;
}

.pdt10 {
    padding-top: 10px;
}

.pdt20 {
    padding-top: 20px;
}

.pdt30 {
    padding-top: 30px;
}

.pdt40 {
    padding-top: 40px;
}

.pdt60 {
    padding-top: 60px;
}

.pdt80 {
    padding-top: 80px;
}

.pdb10 {
    padding-bottom: 10px;
}

.pdb20 {
    padding-bottom: 20px;
}

.pdb30 {
    padding-bottom: 30px;
}

.pdb40 {
    padding-bottom: 40px;
}

.pdb60 {
    padding-bottom: 60px;
}

.pdb80 {
    padding-bottom: 80px;
}

.pdl10 {
    padding-left: 10px;
}

.pdl20 {
    padding-left: 20px;
}

.pdl30 {
    padding-left: 30px;
}

.pdl40 {
    padding-left: 40px;
}

.pdl60 {
    padding-left: 60px;
}

.pdl80 {
    padding-left: 80px;
}

.pdr10 {
    padding-right: 10px;
}

.pdr20 {
    padding-right: 20px;
}

.pdr30 {
    padding-right: 30px;
}

.pdr40 {
    padding-right: 40px;
}

.pdr60 {
    padding-right: 60px;
}

.pdr80 {
    padding-right: 80px;
}


/*--------------------------------
 4.4 Background & Block color
----------------------------------*/
.bg-white {
    background-color: #ffffff;
}

.bg-default {
    background-color: #f2f1f3;
}

.bg-primary {
    background-color: #8f61a9;
}

.bg-secondary {
    background-color: #7d4c9e;
}

.feature-blurb {
    margin-bottom: 30px;
}

.well-box {
    background-color: #fff;
    padding: 30px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
}

body:not(.no-page-loader) .wrapper, .animsition-overlay {
    /* position: relative; */
    opacity: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.section-block {
    padding: 40px;
    word-break: break-word;
}

.section-block-wide {
    position: relative;
}

/*--------------------------------
 4.5 Features Block
----------------------------------*/
.feature-number-block {
    margin-bottom: 30px;
}

.number-block {
    height: 98px;
    width: 94px;
    background-color: #ebe9ec;
    padding: 30px;
    font-size: 24px;
    color: #7d4c9e;
    text-align: center;
    float: left;
}

.feature-content {
    background-color: #f7f6f8;
    padding: 30px;
    padding: 16px 16px 16px 115px;
}

/*--------------------------------
 4.6 Bullet
----------------------------------*/
.angle-style {
    position: relative;
    line-height: 30px;
    padding-left: 20px;
    margin-bottom: 30px;
}

.angle-style li:before {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    left: 0;
    font-size: 14px;
    color: #8c8a8f;
    content: "\f105";
}

.circle-style {
    position: relative;
    line-height: 30px;
    padding-left: 20px;
    margin-bottom: 30px;
}

.circle-style li:before {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    left: 0;
    font-size: 10px;
    color: #6e3f8d;
    content: "\f111";
}

/*--------------------------------
 4.7 Call to actions
----------------------------------*/
.cta {
    padding-bottom: 70px;
    padding-top: 70px;
}

.cta-block {
}

.cta-title {
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    margin-bottom: 20px;
}

.cta-action {
    float: right;
    padding-top: 25px;
}

.small-cta {
    background-color: #f0e3fa;
    padding: 23px;
}

.cta-no {
    background-color: #7d4c9e;
    padding: 23px 10px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
    text-align: center;
}

/*--------------------------------
 4.8 Image Hover Effect
----------------------------------*/

.imghover {
    display: block;
    position: relative;
    max-width: 100%;
}

.imghover::before, .imghover::after {
    display: block;
    position: absolute;
    content: "";
    text-align: center;
    opacity: 0;
}

.imghover::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.imghover::after {
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: -25px 0 0 -25px;
    content: "\f2a8";
    font-family: "FontAwesome";
    font-size: 36px;
}

.imghover:hover::before, .imghover:hover::after {
    opacity: 1;
}

.imghover:hover::before {
    background: rgba(109, 61, 141, .55);
}

.imghover:hover::after {
    color: #FFFFFF;
}

/*--------------------------------
 4.9 Icon
----------------------------------*/
.icon {
}

.icon-1x {
    font-size: 19px;
}

.icon-2x {
    font-size: 30px;
}

.icon-3x {
    font-size: 40px;
}

.icon-4x {
    font-size: 50px;
}

.icon-default {
    color: #7d4c9e;
}

.icon-primary {
    color: #c4c833;
}

.icon-secondary {
    color: #;
}

/*--------------------------------
 4.10 Pagination 頁碼
----------------------------------*/
.st-pagination {
    text-align: center;
}

.st-pagination .pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #a67e7e;
    text-decoration: none;
    font-size: 15px;
    border-radius: 0px;
    background-color: #fff;
    border-color: #a67e7e;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    padding: 9px 15px;
    border-radius: 50%;
}

.st-pagination .pagination > li > a:focus, .st-pagination .pagination > li > a:hover, .st-pagination .pagination > li > a:active, .st-pagination .pagination > li > span:focus, .st-pagination .pagination > li > span:hover {
    z-index: 2;
    color: #fff;
    background-color: #a67e7e;
    border-color: #a67e7e;
}

.st-pagination .pagination > li:first-child > a, .st-pagination .pagination > li:first-child > span {
    margin-left: 0;
}

.st-pagination .pagination > li:last-child > a, .st-pagination .pagination > li:last-child > span {
}

.st-pagination .pagination > .active > a, .st-pagination .pagination > .active > a:focus, .st-pagination .pagination > .active > a:hover, .st-pagination .pagination > .active > span, .st-pagination .pagination > .active > span:focus, .st-pagination .pagination > .active > span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #a67e7e;
    border-color: #a67e7e;
}

.st-pagination .pagination > li > a:hover {
    text-decoration: underline;
    background-color: #b29fd9;
    border-color: #b29fd9;
}


/*--------------------------------
 5 Header / Navigations
----------------------------------*/

/*--------------------
     5.1 Header Regular
    ----------------------*/
.header-flexbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    padding-top: 25px;
    padding-bottom: 15px;
    position: relative;
    z-index: 9;
    color: #fff;
    background: #1a3456;
}

.header-logo a img {
    max-height: 70px;
}

.header-language li {
    list-style: none;
    display: inline-block;
}

.header-language li a {
    width: 35px;
    height: 35px;
    color: #fff;
    display: block;
    text-align: center;
    line-height: 26px;
    border: 3px solid transparent;
    border-radius: 50%;

}

.header-language li a:hover, .header-language li.active a {
    color: #3166ad;
    background-color: #fff;
    border: 3px solid #3166ad;
}

.header-wrapper {
    position: relative;
    z-index: 9;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.navigation-wrapper {
    position: relative;
    z-index: 9;
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 1%, #d9d9d9 100%);
    background: -webkit-linear-gradient(top, #ffffff 1%, #d9d9d9 100%);
    background: linear-gradient(to bottom, #ffffff 1%, #d9d9d9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#d9d9d9', GradientType=0);
}

.header-right {
    float: right;
}

.header-block {
    float: left;
    padding-left: 15px;
}

.call-block {
    padding-top: 10px;
}

.header-block-info {
    float: left;
}

.text-head {
    font-size: 18px;
    text-align: right;
    line-height: 1.2;
}

.text-small {
    font-size: 16px;
}

.text-small i {
    color: #fff;
}

.header-icon {
    float: left;
    margin-right: 5px;
    margin-top: -5px;
    font-size: 22px;
    -moz-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    -o-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
}

.search-box {
    position: relative;
}

.search-box input {
    line-height: 28px;
    font-size: 15px;
    color: #333;
    padding: 3px 15px;
    border-radius: 17px;
    border: none;
    width: 250px;
    outline: none;
}

.search-box button {
    background: transparent;
    border-style: none;
    border-width: 0;
    position: absolute;
    top: 0;
    display: block;
    right: 5px;
    color: #bbb;
    line-height: 34px;

    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.search-box:hover button {
    color: #ddb5e2;
}

.search-box1 {
    float: right;
    margin: 7px 15px;
}


@media screen and (max-width: 1200px) {
    .search-box input {
        width: 150px;
    }
}

@media screen and (max-width: 767px) {
    .header {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .search-box1 {
        z-index: 99;
    }

    .search-box1 input {
        width: 250px;
        line-height: 20px;
        border: 1px solid #ddd;
    }

    .search-box1 button {
        line-height: 24px;
    }

    .header-language {

        margin-right: 5px;

    }

    .header-language li {

        margin-left: -5px;

    }

    .header-language li a {

        width: 32px;

        height: 32px;

    }
}

@media screen and (max-width: 480px) {
    .header-logo .img-responsive {
        max-height: 50px;
    }
}

/*--------------------------
     5.2 Navigations Regular
    ----------------------------*/

.navigation {
    position: relative;
    z-index: 9;
}

#navigation, #navigation ul, #navigation ul li, #navigation ul li a, #navigation #menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#navigation:after, #navigation > ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

#navigation #menu-button {
    display: none;
}

#navigation {
}

#navigation > ul > li {
    float: left;
    text-align: center;
    position: relative;
    width: 14.285%;
}

#navigation.align-center > ul {
    font-size: 0;
    text-align: center;
}

#navigation.align-center > ul > li {
    display: inline-block;
    float: none;
}

#navigation.align-center ul ul {
    text-align: left;
}

#navigation.align-right > ul > li {
    float: right;
}

#navigation > ul > li > a {
    border-right: 1px solid #cecece;
    margin-left: -1px;
    border-left: 1px solid #cecece;
    padding: 13px 33px;
    font-size: 17px;
    text-decoration: none;
    color: #333;
    line-height: 20px;
    overflow: hidden;
}

#navigation > ul > li:hover > a {
    color: #3166ad;
}

#navigation > ul > li > a span {
    display: block;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
}

@media only screen and (min-width: 768px) {
    #navigation > ul > li > a:before {
        content: '';
        width: 100%;
        height: 3px;
        background-color: #1e2d57;
        position: absolute;
        left: 0;
        bottom: -3px;
        -webkit-transition: all .25s ease;
        -moz-transition: all .25s ease;
        -ms-transition: all .25s ease;
        -o-transition: all .25s ease;
        transition: all .25s ease;
    }

    #navigation > ul > li:hover > a:before, #navigation > ul > li.current > a:before {
        bottom: 0;
    }
}


/*#navigation > ul > li.has-sub > a:after {
    position: absolute;
    top: 22px;
    right: 11px;
    width: 8px;
    height: 2px;
    display: block;
    background: #dddddd;
    content: '';
}
*/
/*#navigation > ul > li.has-sub:hover > a:before {
    top: 23px;
    height: 0;
}
*/
#navigation ul ul {
    position: absolute;
    left: -9999px;
    border: 1px solid #ddd;
    z-index: 10;
}

#navigation.align-right ul ul {
    text-align: right;
}

#navigation ul ul li {
    height: 0;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

#navigation li:hover > ul {
    left: auto;
}

#navigation.align-right li:hover > ul {
    left: auto;
    right: 0;
}

#navigation li:hover > ul > li {
    /*height: 46px;*/
    height: auto;
}

#navigation ul ul ul {
    margin-left: 100%;
    top: 0;
}

#navigation.align-right ul ul ul {
    margin-left: 0;
    margin-right: 100%;
}

#navigation ul ul li a {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    width: 200px;
    font-size: 15px;
    text-decoration: none;
    color: #555;
    background: #f3f3f3;
    line-height: 20px;
}

#navigation ul ul li:last-child > a, #navigation ul ul li.last-item > a {
    border-bottom: 0;
}

#navigation ul ul li:hover > a, #navigation ul ul li a:hover {
    color: #fff;
    background-color: #1e2d57;
}

#navigation ul ul li.has-sub > a:after {
    position: absolute;
    top: 21px;
    right: 11px;
    width: 8px;
    height: 2px;
    display: block;
    background: #3166ad;
    content: '';
}

#navigation.align-right ul ul li.has-sub > a:after {
    right: auto;
    left: 11px;
}

#navigation ul ul li.has-sub > a:before {
    position: absolute;
    top: 18px;
    right: 14px;
    display: block;
    width: 2px;
    height: 8px;
    background: #3166ad;
    content: '';
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}

#navigation.align-right ul ul li.has-sub > a:before {
    right: auto;
    left: 14px;
}

#navigation ul ul > li.has-sub:hover > a:before {
    top: 17px;
    height: 0;
}

/*--- Mobile Scree Class ---*/
#navigation.small-screen {
    width: 100%;
    height: 40px;
}

#navigation.small-screen ul {
    width: 100%;
    display: none;
}

#navigation.small-screen.align-center > ul {
    text-align: left;
}

#navigation.small-screen ul li {
    width: 100%;
    background: #f3f3f3;
    border-bottom: 1px solid #cfcfcf;
}

#navigation.small-screen ul ul li, #navigation.small-screen li:hover > ul > li {
    height: auto;
}

#navigation.small-screen ul li a, #navigation.small-screen ul ul li a {
    width: 100%;
    border-bottom: 0;
    padding: 15px 25px;
}

#navigation.small-screen > ul > li {
    text-align: left;
}

#navigation.small-screen > ul > li > a span {
    display: inline-block;
    font-size: 14px;
    margin-left: 15px;
    color: #3166ad;
}

#navigation.small-screen > ul > li {
    float: none;
}

#navigation.small-screen ul ul li a {
    padding-left: 50px;
}

#navigation.small-screen ul ul ul li a {
    padding-left: 70px;
}

#navigation.small-screen ul ul li a {
    color: #777;
    background: none;
}

#navigation.small-screen ul ul li:hover > a, #navigation.small-screen ul ul li.active > a {
    color: #555;
}

#navigation.small-screen ul ul, #navigation.small-screen ul ul ul, #navigation.small-screen.align-right ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
}

#navigation.small-screen ul ul, #navigation.small-screen ul ul li:last-child {
    border-bottom: none;
}

#navigation.small-screen > ul > li.has-sub > a:after, #navigation.small-screen > ul > li.has-sub > a:before, #navigation.small-screen ul ul > li.has-sub > a:after, #navigation.small-screen ul ul > li.has-sub > a:before {
    display: none;
}

#navigation.small-screen #menu-button {
    display: block;
    padding: 10px;
    width: 40px;
    height: 40px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}

#navigation.small-screen #menu-button:after {
    position: absolute;
    top: 18px;
    right: 10px;
    display: block;
    height: 8px;
    width: 20px;
    border-top: 2px solid #888;
    border-bottom: 2px solid #888;
    content: '';
}

#navigation.small-screen #menu-button:before {
    position: absolute;
    top: 12px;
    right: 10px;
    display: block;
    height: 2px;
    width: 20px;
    background: #888;
    content: '';
}

#navigation.small-screen #menu-button.menu-opened:after {
    top: 19px;
    border: 0;
    height: 2px;
    width: 15px;
    background: #3166ad;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#navigation.small-screen #menu-button.menu-opened:before {
    top: 19px;
    background: #3166ad;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#navigation.small-screen .submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    height: 52px;
    width: 50px;
    cursor: pointer;
}

#navigation.small-screen .submenu-button.submenu-opened {
    background: #3166ad;
}

#navigation.small-screen ul ul .submenu-button.submenu-opened {
    background: #9a6bc7;
}

#navigation.small-screen ul ul .submenu-button {
    height: 46px;
    width: 40px;
}

#navigation.small-screen .submenu-button:after {
    position: absolute;
    top: 22px;
    right: 19px;
    width: 8px;
    height: 2px;
    display: block;
    background: #c192df;
    content: '';
}

#navigation.small-screen .submenu-button.submenu-opened::after {
    top: 26px;
}

#navigation.small-screen ul ul .submenu-button.submenu-opened::after {
    top: 22px;
}

#navigation.small-screen ul ul .submenu-button:after {
    top: 20px;
    right: 13px;
}

#navigation.small-screen .submenu-button.submenu-opened:after {
    background: #ffffff;
}

#navigation.small-screen .submenu-button:before {
    position: absolute;
    top: 19px;
    right: 22px;
    display: block;
    width: 2px;
    height: 8px;
    background: #c294e0;
    content: '';
}

#navigation.small-screen ul ul .submenu-button:before {
    top: 17px;
    right: 16px;
}

#navigation.small-screen .submenu-button.submenu-opened:before {
    display: none;
}

#navigation.small-screen.select-list {
    padding: 5px;
}

@media only screen and (max-width: 1200px) {
    #navigation > ul > li > a {
        padding: 10px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .navigation-wrapper .container {
        padding: 0;
    }
}


/*--------------------------
     5.2 Navigations NEW
    ----------------------------*/
.logo {
    position: relative;
    z-index: 123;
}

.logo a {
    color: #6DDB07;
}

nav {
    position: relative;
    width: 980px;
    margin: 0 auto;
}

#cssmenu, #cssmenu ul, #cssmenu ul li, #cssmenu ul li a, #cssmenu #head-mobile {
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

#cssmenu:after, #cssmenu > ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

#cssmenu #head-mobile {
    display: none
}

#cssmenu {
    font-family: sans-serif;
    background: #333
}

#cssmenu > ul > li {
    float: left
}

#cssmenu > ul > li > a {
    padding: 17px;
    font-size: 12px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #ddd;
    font-weight: 700;
}

#cssmenu > ul > li:hover > a, #cssmenu ul li.active a {
    color: #fff
}

#cssmenu > ul > li:hover, #cssmenu ul li.active:hover, #cssmenu ul li.active, #cssmenu ul li.has-sub.active:hover {
    background: #448D00 !important;
    -webkit-transition: background .3s ease;
    -ms-transition: background .3s ease;
    transition: background .3s ease;
}

#cssmenu > ul > li.has-sub > a {
    padding-right: 30px
}

#cssmenu > ul > li.has-sub > a:after {
    position: absolute;
    top: 22px;
    right: 11px;
    width: 8px;
    height: 2px;
    display: block;
    background: #ddd;
    content: ''
}

#cssmenu > ul > li.has-sub > a:before {
    position: absolute;
    top: 19px;
    right: 14px;
    display: block;
    width: 2px;
    height: 8px;
    background: #ddd;
    content: '';
    -webkit-transition: all .25s ease;
    -ms-transition: all .25s ease;
    transition: all .25s ease
}

#cssmenu > ul > li.has-sub:hover > a:before {
    top: 23px;
    height: 0
}

#cssmenu ul ul {
    position: absolute;
    left: -9999px
}

#cssmenu ul ul li {
    height: 0;
    -webkit-transition: all .25s ease;
    -ms-transition: all .25s ease;
    background: #333;
    transition: all .25s ease
}

#cssmenu ul ul li:hover {
}

#cssmenu li:hover > ul {
    left: auto
}

#cssmenu li:hover > ul > li {
    height: 35px
}

#cssmenu ul ul ul {
    margin-left: 100%;
    top: 0
}

#cssmenu ul ul li a {
    border-bottom: 1px solid rgba(150, 150, 150, 0.15);
    padding: 11px 15px;
    width: 170px;
    font-size: 12px;
    text-decoration: none;
    color: #ddd;
    font-weight: 400;
}

#cssmenu ul ul li:last-child > a, #cssmenu ul ul li.last-item > a {
    border-bottom: 0
}

#cssmenu ul ul li:hover > a, #cssmenu ul ul li a:hover {
    color: #fff
}

#cssmenu ul ul li.has-sub > a:after {
    position: absolute;
    top: 16px;
    right: 11px;
    width: 8px;
    height: 2px;
    display: block;
    background: #ddd;
    content: ''
}

#cssmenu ul ul li.has-sub > a:before {
    position: absolute;
    top: 13px;
    right: 14px;
    display: block;
    width: 2px;
    height: 8px;
    background: #ddd;
    content: '';
    -webkit-transition: all .25s ease;
    -ms-transition: all .25s ease;
    transition: all .25s ease
}

#cssmenu ul ul > li.has-sub:hover > a:before {
    top: 17px;
    height: 0
}

#cssmenu ul ul li.has-sub:hover, #cssmenu ul li.has-sub ul li.has-sub ul li:hover {
    background: #363636;
}

#cssmenu ul ul ul li.active a {
    border-left: 1px solid #333
}

#cssmenu > ul > li.has-sub > ul > li.active > a, #cssmenu > ul ul > li.has-sub > ul > li.active > a {
    border-top: 1px solid #333
}

@media screen and (max-width: 1000px) {
    .logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        float: none
    }

    .logo2 {
        display: none
    }

    nav {
        width: 100%;
    }

    #cssmenu {
        width: 100%
    }

    #cssmenu ul {
        width: 100%;
        display: none
    }

    #cssmenu ul li {
        width: 100%;
        border-top: 1px solid #444
    }

    #cssmenu ul li:hover {
        background: #363636;
    }

    #cssmenu ul ul li, #cssmenu li:hover > ul > li {
        height: auto
    }

    #cssmenu ul li a, #cssmenu ul ul li a {
        width: 100%;
        border-bottom: 0
    }

    #cssmenu > ul > li {
        float: none
    }

    #cssmenu ul ul li a {
        padding-left: 25px
    }

    #cssmenu ul ul li {
        background: #333 !important;
    }

    #cssmenu ul ul li:hover {
        background: #363636 !important
    }

    #cssmenu ul ul ul li a {
        padding-left: 35px
    }

    #cssmenu ul ul li a {
        color: #ddd;
        background: none
    }

    #cssmenu ul ul li:hover > a, #cssmenu ul ul li.active > a {
        color: #fff
    }

    #cssmenu ul ul, #cssmenu ul ul ul {
        position: relative;
        left: 0;
        width: 100%;
        margin: 0;
        text-align: left
    }

    #cssmenu > ul > li.has-sub > a:after, #cssmenu > ul > li.has-sub > a:before, #cssmenu ul ul > li.has-sub > a:after, #cssmenu ul ul > li.has-sub > a:before {
        display: none
    }

    #cssmenu #head-mobile {
        display: block;
        padding: 23px;
        color: #ddd;
        font-size: 12px;
        font-weight: 700
    }

    .button {
        width: 55px;
        height: 46px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        z-index: 12399994;
    }

    .button:after {
        position: absolute;
        top: 22px;
        right: 20px;
        display: block;
        height: 4px;
        width: 20px;
        border-top: 2px solid #dddddd;
        border-bottom: 2px solid #dddddd;
        content: ''
    }

    .button:before {
        -webkit-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
        position: absolute;
        top: 16px;
        right: 20px;
        display: block;
        height: 2px;
        width: 20px;
        background: #ddd;
        content: ''
    }

    .button.menu-opened:after {
        -webkit-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
        top: 23px;
        border: 0;
        height: 2px;
        width: 19px;
        background: #fff;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    .button.menu-opened:before {
        top: 23px;
        background: #fff;
        width: 19px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

    #cssmenu .submenu-button {
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        display: block;
        border-left: 1px solid #444;
        height: 46px;
        width: 46px;
        cursor: pointer
    }

    #cssmenu .submenu-button.submenu-opened {
        background: #262626
    }

    #cssmenu ul ul .submenu-button {
        height: 34px;
        width: 34px
    }

    #cssmenu .submenu-button:after {
        position: absolute;
        top: 22px;
        right: 19px;
        width: 8px;
        height: 2px;
        display: block;
        background: #ddd;
        content: ''
    }

    #cssmenu ul ul .submenu-button:after {
        top: 15px;
        right: 13px
    }

    #cssmenu .submenu-button.submenu-opened:after {
        background: #fff
    }

    #cssmenu .submenu-button:before {
        position: absolute;
        top: 19px;
        right: 22px;
        display: block;
        width: 2px;
        height: 8px;
        background: #ddd;
        content: ''
    }

    #cssmenu ul ul .submenu-button:before {
        top: 12px;
        right: 16px
    }

    #cssmenu .submenu-button.submenu-opened:before {
        display: none
    }

    #cssmenu ul ul ul li.active a {
        border-left: none
    }

    #cssmenu > ul > li.has-sub > ul > li.active > a, #cssmenu > ul ul > li.has-sub > ul > li.active > a {
        border-top: none
    }
}


/*--------------------------
     5.3 Page Header
    ----------------------------*/
.page-header {
    background: linear-gradient(rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)), rgba(0, 0, 0, 0.1) url(/images/page-header.jpg) no-repeat center;
    background-size: cover;
    margin: 0;
    padding: 0px;
}

.page-section {
    padding: 120px 0px 120px 0px;
}

.page-caption {
}

.page-title {
    color: #fff;
    font-size: 42px;
    position: relative;
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 40px;
}

.page-text {
    font-size: 16px;
    color: #fff;
}

.page-breadcrumb {
    background-color: transparent;
    margin-top: 10px;
}

.page-breadcrumb .breadcrumb {
    margin-bottom: 0px;
    font-size: 14px;
    background-color: transparent;
    position: relative;
    display: inline-block;
    padding: 0px;
}

.page-breadcrumb .breadcrumb > li a {
    color: #7e7881;
}

.page-breadcrumb .breadcrumb > li a:hover {
    color: #3166ad;
}

.page-breadcrumb .breadcrumb > .active {
    color: #3166ad;
}

.page-breadcrumb .breadcrumb > li + li:before {
    padding: 0 5px;
    color: #7e7881;
    content: "\f105";
    font-family: FontAwesome;
}

.breadcrumb > li {
    display: inline-block;
}


/*-----------------------------------
     6. Slider
-------------------------------------*/
.slider {
    position: relative;
}

.slider-img {
    position: relative;
    overflow: hidden;
}

.slider-img img {
    position: relative;
}

.slider .owl-prev {
    position: absolute;
    top: 40%;
    left: 10px;
}

.slider .owl-next {
    position: absolute;
    top: 40%;
    right: 10px;
}

.slider .owl-prev, .slider .owl-next {
    padding: 10px 16px;
    border-radius: 2px;
    font-size: 40px;
}

.slider .owl-prev:hover, .slider .owl-next:hover {
    background-color: #8e517d;
    color: #fff;
}

.slider .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 40px;
    margin: 5px;
    padding: 4px 12px;
    display: inline-block;
    cursor: pointer;
    border-radius: 2px;
    background: transparent;
    text-shadow: 0 0 8px rgba(98, 34, 104, 0.5);
    -webkit-text-shadow: 0 0 8px rgba(98, 34, 104, 0.5);
    -moz-text-shadow: 0 0 8px rgba(98, 34, 104, 0.5);
    opacity: 0.7;
}

.slider .owl-theme .owl-nav [class*='owl-']:hover {
    color: #3166ad;
    background: transparent;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.7);
    -webkit-text-shadow: 0 0 8px rgba(255, 255, 255, 0.7);
    -moz-text-shadow: 0 0 8px rgba(255, 255, 255, 0.7);
    text-decoration: none;
    opacity: 1;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.slider .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px;
    background: transparent;
    border: 1px solid #2463a7;
    display: block;
    -webkit-backface-visibility: visible;
    border-radius: 50%;

    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.slider .owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    bottom: 40px;
    position: relative;
}

.slider .owl-theme .owl-dots .owl-dot.active span, .slider .owl-theme .owl-dots .owl-dot:hover span {
    background: #2463a7;
}


@media only screen and (min-width: 1200px) {
    .slider .owl-prev {
        left: 10%;
    }

    .slider .owl-next {
        right: 10%;
    }
}

@media only screen and (max-width: 767px) {
    .slider .owl-prev {
        top: 45%;
        left: 0;
    }

    .slider .owl-next {
        top: 45%;
        right: 0;
    }
}


/*-----------------------------------
7. Content
-------------------------------------*/
.content {
    padding-bottom: 100px;
    padding-top: 100px;
}

.sidebar {
}

.sidebar-area {
}

/*-----------------------------------
8. Footer
-------------------------------------*/
.footer {
    padding-top: 40px;
    padding-bottom: 15px;
    background-color: #1d2731;
    color: #cecad4;
    border-bottom: 10px solid #1d1d1e;
}

.footer-small {
    font-size: 14px;
}

.footer .fa-map-marker {
    color: #3166ad;
    margin-right: 5px;
}

.widget-footer {
    font-size: 15px;
}

.widget-footer .call-no {
    font-size: 22px;
    color: #c4c833;
    margin-bottom: 10px;
}

.widget-footer .widget-title {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 18px;
    color: #fff;
    border-bottom: none;
}

.text-title {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
}

.widget-footer ul {
    padding: 0px;
}

.widget-footer ul li {
}

.widget-footer ul li a {
    color: #918f94;
    padding-left: 20px;
}

.widget-footer ul li a:hover {
    color: #c4c833;
}

.footer .contact li {
    position: relative;
    display: block;
    margin-bottom: 1px;
    padding: 0 0 15px 40px;
}

.footer .contact li i {
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
    width: 30px;
    font-size: 16px;
    text-align: center;
}

.widget-newsletter {
}

.widget-newsletter .widget-title {
}

.widget-newsletter .form-control {
    background-color: #39373a;
    border-color: #39373a;
    margin-bottom: 10px;
}

.tiny-footer {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #252426;
    font-size: 14px;
}

.footer-social {
    float: right;
    margin-top: 5px;
}

.footer-social li {
    list-style: none;
    display: inline-block;
    margin-left: 7px;
    font-size: 22px;
}

.footer-social li a {
    background-color: #32516e;
    width: 50px;
    height: 50px;
    display: block;
    color: #cecad4;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
}

.footer-social li a:hover {
    background-color: #585858;
    color: #fff;
}

.footer-social li a.f-icon-line span, .footer-social li a.f-icon-pixnet span {
    display: block;
    width: 100%;
    height: 100%;
    background: url('/images/icon/footer-icon.png') -50px 0 no-repeat;
    text-indent: -9999px;
}

.footer-social li a.f-icon-pixnet span {
    background-position: 0 0;
}

.footer-social li a.f-icon-pixnet:hover span {
    background-position: 0 -50px;
}

.footer-social li a.f-icon-line:hover span {
    background-position: -50px -50px;
}


@media only screen and (max-width: 767px) {
    .footer.mt100 {
        margin-top: 40px;
    }

    .footer .img-responsive {
        margin: auto;
    }

    .footer-social {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .footer-social li {
        margin-bottom: 10px;
    }

    ul.footer-social {
        padding: 0 20px;
    }
}


/*------------------------
  9.1 About us
--------------------------*/

/*-------------------------
  9.2  Service / single / sidebar
--------------------------*/

.treatment-block {
    margin-bottom: 40px;
}

.treatment-img {
    margin-bottom: 30px;
}

.treatment-img img {
    width: 100%;
}

.treatment-content {
}

/*-----------------------------------------
     service side-nav
    ------------------------------------------*/

.sidenav {
    margin-bottom: 30px;
}

.sidenav ul {
    padding: 0px;
}

.sidenav ul li {
    position: relative;
}

.sidenav ul li a {
    padding: 17px;
    display: block;
    background-color: #f0e3fa;
    color: #5f5664;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    border-color: #f0e3fa;
    margin-bottom: 1px;
}

.sidenav ul li a:hover {
    color: #7d4c9e;
    background-color: #e6d3f5;
    border-color: #e6d3f5;
}

.sidenav ul li.active a {
    color: #7d4c9e;
    background-color: #e6d3f5;
    border-color: #e6d3f5;
}

.sidenav ul li:last-child {
    border-bottom: 0px solid #7d4c9e;
}

.st-accordion {
}

.st-accordion .sign {
    color: #d34836;
    padding: 0 2px;
    position: absolute;
    left: -30px;
    top: 2px;
}

.st-accordion .accordion .panel-heading.active .sign {
    color: #d34836;
}

.st-accordion .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #383433;
    position: relative;
    right: -10px;
}

.st-accordion .panel-heading {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.st-accordion .panel {
    margin-bottom: 20px;
    background-color: #fff;
}

.st-accordion .panel-default {
    border-color: #e3e2e2;
}

.st-accordion .panel-group .panel {
    margin-bottom: 10px;
    border-radius: 0px;
    background-color: #f7f6f8;
}

.st-accordion .panel-default > .panel-heading {
    color: #323734;
    background-color: #f7f6f8;
    padding: 16px 10px;
}

.st-accordion .panel-group .panel-heading + .panel-collapse > .list-group,
.st-accordion .panel-group .panel-heading + .panel-collapse > .panel-body {
}

.st-accordion .panel-body {
    padding: 30px;
}

.st-accordion .panel-body p:first-child {
}

.st-accordion .panel-body p:last-child {
    margin-bottom: 0px;
}

.st-accordion .panel-group .panel-heading + .panel-collapse > .list-group,
.panel-group .panel-heading + .panel-collapse > .panel-body {
}


/*--------------------------
  9.7  Team
---------------------------*/
.team-block {
    position: relative;
}

.team-img {
    margin-bottom: 20px;
    position: relative;
}

.team-content {
    position: absolute;
    right: 40px;
    bottom: 40px;
    width: 400px;
}

.team-title {
    margin-bottom: 10px;
    line-height: .6;
}

.team-meta {
    color: #d34836;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.doctor-social {
}

.doctor-social li {
    list-style: none;
    display: inline-block;
}

.doctor-social li a {
    background-color: #efedf1;
    width: 36px;
    height: 36px;
    display: block;
    color: #807e82;
    text-align: center;
    line-height: 2.5;
}

.doctor-social li a:hover {
    background-color: #c4c833;
    color: #fff;
}


/*--------------------------
  9.7  Testimonial
---------------------------*/

.testimonial-block {
    text-align: center;
}

.testimonial-content {
    font-size: 21px;
    line-height: 31px;
    color: #504e53;
    font-style: italic;
    margin-bottom: 40px;
}

.testimonial-info {
    padding-left: 100px;
    padding-top: 20px;
}

.testimonial-user {
}

.testimonial-name {
    margin-bottom: 0px;
    font-size: 12px;
    color: #2c2530;
    text-transform: uppercase;
}

.testimonial-meta {
    font-size: 14px;
    color: #8f61a9;
    font-weight: 600;
}

.testimonial-img {
    float: left;
}

.testimonial-dtl {
    display: inline-block;
}

.testimonial .owl-nav {
    display: none;
}

.testimonial.owl-theme .owl-dots {
    text-align: center;
}

.testimonial.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #f6e9ff;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 0px;
}

.testimonial.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    bottom: -20px;
    position: relative;
}

.testimonial.owl-theme .owl-dots .owl-dot.active span, .testimonial .owl-theme .owl-dots .owl-dot:hover span {
    background: #7d4c9e;
}

.testimonial-box {
    background-color: #f7f6f8;
    padding: 30px;
    margin-bottom: 30px;
}

.testimonial-box .testimonial-content {
    font-size: 19px;
    color: #666369;
    line-height: 30px;
}

.tesimonial-detail {
}

.testimonial-box .testimonial-name {
    font-size: 12px;
    color: #2c2530;
    text-transform: uppercase;
}

/*------------------------
  9.10 Location
--------------------------*/
.location-block {
    margin-bottom: 50px;
}

.location-map {
    height: 270px;
    margin-bottom: 30px;
}

.location-info {
}

.address-block {
}

.call-block {
}

.call-block .call-no {
    font-size: 22px;
    color: #7d4c9e;
}

.mail-block {
}

/*--------------------------
  9.10  Gallery
---------------------------*/


/*-------- filter gallery -------*/

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

.isotope, .isotope .isotope-item { /* change duration value to whatever you like */
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    transition-property: transform, opacity;
}

.portfolioContainer {
}

.portfolioFilter {
    margin-bottom: 30px;
    text-align: center;
}

.portfolioFilter a {
    text-transform: uppercase;
    background-color: #666467;
    padding: 9px 26px;
    display: inline-block;
    color: #eae7eb;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.portfolioFilter a:hover {
    color: #fff;
    background-color: #6d3d8d;
}

.portfolioFilter a.current {
    color: #fff;
    text-decoration: none;
    background-color: #7d4c9e;
}

/*----------------- Gallery Wide ---------------*/

.gallery-thumbnail img {
    width: 100%;
}

.gallery-img img {
    width: 100%;
}

.gallery-block {
}


/*--------------------------
  9.12 Contact-us
---------------------------*/
.contact-form {
    padding: 30px;
}

.map {
    height: 350px;
}

.widget-contact {
    background-color: #f2f1f3;
    padding: 30px;
    margin-bottom: 30px;
}

/*------------------------
 10. Blog pages
--------------------------*/
.post-block {
    position: relative;
}

.post-img {
    position: relative;
}

.post-img img {
    width: 100%;
}

.sticky-box {
    height: 42px;
    width: 40px;
    background-color: #7d4c9e;
    position: absolute;
    z-index: 9;
    top: 0px;
    right: 0px;
}

.post-sticky {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 9;
    color: #fff;
    font-size: 20px;
}

.post-content {
}

.post-block h1 {
    line-height: 26px;
    margin-bottom: 20px;
}

.post-title {
    color: #333536;
    font-size: 24px;
}

.meta {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 6px;
    text-align: center;
}

.meta span {
    margin-right: 40px;
}

.meta span i {
    font-size: 13px;
    color: #8f61a9;
    padding-right: 5px;
}

.meta-date, .meta-user, .meta-comment, .meta-category {
}

.meta-date {
}

.meta-date span {
    margin-bottom: 0px;
}

.meta-user {
}

.meta-comment {
}

.meta-box {
    height: 42px;
    width: 64px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 1;
    font-weight: 700;
    background-color: #3b383d;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    padding: 5px;
}

.post-gallery {
}

.post-gallery .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #f6e9ff;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 0px;
}

.post-gallery .owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    bottom: 50px;
    position: relative;
}

.post-gallery .owl-theme .owl-dots .owl-dot.active span, .post-gallery .owl-theme .owl-dots .owl-dot:hover span {
    background: #7d4c9e;
}


.widget .widget-title {
    margin-bottom: 30px;
}

.widget-hours {
}

.widget-hours-day {
    width: 120px;
    display: inline-block;
    font-weight: 700;
    color: #373332;
}

.widget-hours-time {
    float: right;
    text-transform: uppercase;
}

.widget-hours li {
    margin-bottom: 10px;
}

.widget {
    background-color: #f2f1f3;
    padding: 30px;
    margin-bottom: 30px;
}

.widget-title {
    font-size: 18px;
    color: #2c2530;
    font-weight: 700;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 9px;
    padding-bottom: 10px;
}

.quote-block {
    background-color: #7d4c9e;
    padding: 40px;
    margin-bottom: 40px;
}

.quote-block blockquote {
    padding: 0px 50px;
    margin: 0 0 20px;
    font-size: 28px;
    border-left: 3px solid #fff;
    color: #fff;
    line-height: 40px;
    font-weight: 500;
    font-style: italic;
}


/*------------------------
  11. Blog-Sidebar / Widgets
--------------------------*/
.form-title-block {
    background-color: #4d4751;
    font-size: 22px;
    color: #fff;
    padding: 15px;
}

.widget-appointments {
    padding: 30px;
    margin-bottom: 30px;
}

.widget-appointments .input-group .form-control:first-child {
    background-color: #fff;
    border-color: #dadedf;
}


.widget-location {
    background-color: #7d4c9e;
    padding: 30px;
}

.widget-location-icon {
    color: #fff;
    font-size: 56px;
    margin-bottom: 30px;
}

.post-gallery {
}

.post-gallery .owl-dots .owl-dot {
    position: relative;
    text-align: left;
    bottom: 50px;
    max-width: 1140px;
    margin: 0 auto;
}

.post-gallery .owl-dots .owl-dot {
    bottom: 80px;
}

.post-gallery .owl-dots .owl-dot span {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0px 0px 10px 10px;
    filter: Alpha(Opacity=50); /*IE7 fix*/
    opacity: 0.8;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    background-color: #e7e2ea;
    float: left;
}

.post-gallery .owl-dots .owl-dot.active span {
    background-color: #8f61a9;
}

.post-gallery .owl-dots .owl-dot.active span, .post-gallery .owl-controls.clickable .owl-page:hover span {
    background-color: #8f61a9;
    filter: Alpha(Opacity=100); /*IE7 fix*/
    opacity: 1;
}

.widget-search {
    margin-bottom: 30px;
}

.custom-search-input .form-control {
    height: 60px;
}

.custom-search-input .search-query {
    /* IE7-8 doesn't have border-radius, so don't indent the padding */
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.custom-search-input button {
    border: 0;
    background: none; /** belows styles are working good */
    padding: 2px 5px;
    margin-top: 2px;
    position: relative;
    left: -28px;
    /* IE7-8 doesn't have border-radius, so don't indent the padding */
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #8e517d;
    box-shadow: -1px 4px 5px 4px rgba(216, 212, 204, 0);
}

.input-group .form-control:first-child {
    background-color: #f2f1f3;
    border-color: #f2f1f3;
}

search-query:focus + button {
    z-index: 3;
}

.widget-category {
}

.widget-category ul, .widget-archives ul {
}

.widget-category ul li, .widget-archives ul li {
    font-size: 15px;
    color: #58505c;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 23px;
}

.widget-category ul li a, .widget-archives ul li a {
    font-size: 15px;
    color: #58505c;
    font-weight: 600;
}

.widget-category ul li a:hover, .widget-archives ul li a:hover {
    color: #7d4c9e;
}

.widget-archives {
}

.widget-recent-post {
}

.recent-block {
}

.recent-img {
    position: relative;
}

.recent-content {
}

.recent-title {
    font-size: 15px;
    color: #2c2530;
    line-height: 22px;
    font-weight: 600;
}

.widget-tags {
}

.widget-tags a {
    text-transform: uppercase;
    padding: 6px 12px;
    border: 1px solid #b0acb4;
    background-color: #b0acb4;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 12px;
    color: #fff;
}

.widget-tags a:hover {
    background-color: #7d4c9e;
    color: #fff;
    border: 1px solid #7d4c9e;
}

.related-post {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 19px;
    padding-bottom: 20px;
}

.related-post-title {
    margin-bottom: 40px;
}

.related-post-block {
}

.related-content {
}

.related-img {
    margin-bottom: 30px;
}

.related-title {
    font-size: 18px;
    color: #2c2530;
    margin-bottom: 10px;
    line-height: 26px;
}

.related-post .meta {
    border-bottom: none;
    margin-bottom: 0px;
    text-align: left;
}

.post-navigation {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 19px;
    padding-bottom: 20px;
    background-color: #f2f1f3;
    padding: 30px;
}

.nav-links {
}

.nav-previous {
}

.prev-link, .next-link {
    color: #9a9a9b;
    margin-bottom: 10px;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
}

.nav-next {
}

.next-link {
}

.prev-link {
}

.prev-link:hover, .next-link:hover {
    color: #7d4c9e;
}

.next-title {
}

.previous-title {
}

.next-title, .previous-title {
    line-height: 24px;
    font-size: 18px;
    color: #2c2530;
}

.post-author {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 19px;
    padding-bottom: 20px;
    text-align: center;
}

.author-block {
}

.post-author-name {
    font-size: 20px;
    color: #524c56;
    margin-bottom: 10px;
}

.author-content {
    font-size: 16px;
    margin-bottom: 20px;
}

.author-info {
}

.author-header {
    margin-bottom: 30px;
}

.author-img {
    display: inline-block;
    margin-bottom: 30px;
}

/*----- comments-----*/
.comments-area {
    margin-bottom: 40px;
}

.comments-title {
    margin-bottom: 40px;
}

.comment-list {
    padding: 0px;
}

.comment {
}

.comment-body {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 39px;
    padding-bottom: 40px;
}

.comment-body:last-child {
}

.comment-author {
}

.comment-author img {
    float: left;
}

.comment-info {
    padding-left: 130px;
}

.comment-header {
}

.user-title {
    font-size: 16px;
    color: #2c2530;
    margin-bottom: 0px;
    font-weight: 600;
}

.comment-meta {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 10px;
}

.comment-meta-date {
    font-size: 13px;
    color: #7d4c9e;
    font-weight: 600;
}

.comment-content {
}

.reply {
}

.reply-title {
    margin-bottom: 40px;
}

.childern {
    padding-left: 130px;
}

.childern .comment {
}

.childern .comment-body {
}

.leave-comments {
    background-color: #f0f0f0;
    padding: 30px;
    border: 1px solid #e3e2e2;
}

.reply-form {
}

/*------------------------
  12. Error-page
--------------------------*/
.error-block {
}

.error-icon {
    font-size: 250px;
    color: #774896;
    text-align: center;
}

.error-no {
    font-size: 150px;
    color: #2c2530;
    font-weight: 700;
    text-align: center;
}

/*--------------------------------
     12.1 Tabs
    ----------------------------------*/
.st-tabs {
    margin-bottom: 40px;
}

.st-tabs .nav-tabs {
    border-bottom: 1px solid transparent;
}

.st-tabs .nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 0px 0px 0 0;
    background-color: #e7e1eb;
    padding: 14px 40px;
    color: #373332;
    font-size: 18px;
}

.st-tabs .nav-tabs > li > a:hover {
    background-color: #7d4c9e;
    color: #fff;
}

.st-tabs .nav-tabs > li.active > a, .st-tabs .nav-tabs > li.active > a:focus, .st-tabs .nav-tabs > li.active > a:hover {
    color: #fff;
    cursor: default;
    background-color: #7d4c9e;
    border: 1px solid #7d4c9e;
    border-bottom-color: transparent;
}

.st-tabs .tab-content {
    background-color: #fff;
    padding: 40px;
    border: 1px solid #e9ecf1;
}


/*--------------------------------
 13. Extra Plugin CSS - Back to top
---------------------------------*/
.cd-top {
    display: inline-block;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 30px;
    right: 10px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    visibility: hidden;
    background: rgba(36, 99, 167, 100) url(/images/cd-top-arrow.svg) no-repeat center 50%;
    opacity: 0;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s;
    -moz-transition: opacity .3s 0s, visibility 0s .3s;
    transition: opacity .3s 0s, visibility 0s .3s;
    border-radius: 5px;
}

.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
    /* the button becomes visible */
    visibility: visible;
    opacity: 1;
}

.no-touch .cd-top.cd-fade-out:hover {
    background-color: #6f4a52;
    opacity: 1;
}

@media only screen and (max-width: 480px) {
    .cd-top.cd-fade-out {
        /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        opacity: .6;
    }
}


/*------------------------
  15. index
--------------------------*/
/*-----------------------
    15.1 index > service icon
-------------------------*/
.index-ser1 ul {
    text-align: center;
}

.index-ser1 ul li {
    display: inline-block;
    text-align: center;
    width: 16%;
    height: auto;
    outline: none;
    border-right: 1px solid #f1e4e4;
    padding: 10px 0;
}

.index-ser1 ul li:first-child {
    border-left: 1px solid #f1e4e4;
}

.index-ser1 ul li a {
    display: block;
    text-align: center;
    position: relative;
    overflow: hidden;
    line-height: 24px;
    font-size: 11px;
}

.index-ser1 ul li a img {
    display: table;
    display: block;
    width: 100%;
    max-width: 70px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 10px;
}

.index-ser1 ul li a span {
    font-size: 16px;
    color: #555;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.index-ser1 ul li a:hover span {
    color: #3166ad;
}

@media only screen and (max-width: 767px) {
    .index-ser1 ul li {
        font-size: 0;
    }

    .index-ser1 ul li {
        width: 32%;
        border: none;
        position: relative;
        font-size: 0;
    }

    .index-ser1 ul li:nth-child(3n) {
        border-right: none;
        border: none;
    }

    .index-ser1 ul li:first-child {
        border-left: none;
    }

    .index-ser1 ul li:nth-child(-n+3) {
        border-bottom: 1px solid #f1e4e4;
        border: none;
    }

    .index-ser1 ul li a img {
        max-width: 50px;
    }

    .index-ser1 ul li a span {
        font-size: 14px;
    }

    .index-ser1 ul li:before {
        content: '';
        display: block;
        position: absolute;
        height: 80%;
        width: 1px;
        background-color: #f1e4e4;
        bottom: 10%;
        right: 0;
    }

    .index-ser1 ul li:nth-child(3n):before {
        content: none;
    }

    .index-ser1 ul li:nth-child(-n+3):after {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 80%;
        background-color: #f1e4e4;
        bottom: 0;
        left: 10%;
    }
}


/*-----------------------
    15.2 index > news
-------------------------*/
.index-news {
    background: #f6f6f6;
    padding: 30px 0px 0;
    margin-top: 30px;
}

.index-title h2,
.index-title h1 {
    font-size: 26px;
    font-weight: normal;
    color: #5f4e84;
    margin-bottom: 0;
}

@supports (-webkit-background-clip: text) {
    .index-title h2,
    .index-title h1 {
        display: initial;
        background: -webkit-linear-gradient(#2463a7, #1e2d57);
        -webkit-background-clip: text;
        color: transparent !important;
    }
}

.section-title.text-white h2,
.section-title.text-white h3 {
    color: #fff !important;
}

.section-title.text-white h2:after {
    content: '';
    display: block;
    width: 300px;
    height: 1px;
    margin: 5px auto;
    background: #fff;
}

.index-title h2:after,
.index-title h1:after {
    content: '';
    display: block;
    width: 300px;
    height: 1px;
    margin: 5px auto;
    background: #2463a7;
    background: -moz-linear-gradient(left, #2463a7 0%, #1e2d57 100%);
    background: -webkit-linear-gradient(left, #2463a7 0%, #1e2d57 100%);
    background: linear-gradient(to right, #2463a7 0%, #1e2d57 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbc2eb', endColorstr='#a18cd1', GradientType=1);
}

.index-title h3 {
    font-size: 17px;
    line-height: 22px;
    color: #2463a7;
    font-weight: normal;
}

.news-block {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    max-height: 420px;
    overflow: hidden;
    margin: 20px 10px;
}

.news-img {
    width: 100%;
    height: 0;
    padding-bottom: 52.5%;
    position: relative;
    overflow: hidden;
}

.news-img img {
    width: 100%;
    position: absolute;
}

.news-content {
    padding: 10px 20px;
}

.news-content p {
    margin-bottom: 5px;
}

.news-content .news-date, .news-content .news-cate {
    font-size: 14px;
    color: #888;
}

.news-content .news-cate {
    color: #2463a7;
}

.news-content .news-title {
    color: #7e7881;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 52px;
}

.news-content .news-title, .news-block {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.news-block a:hover .news-content .news-title {
    color: #3166ad;
}

.news-block:hover {
    border: 1px solid #3166ad;
}


.news-block .row > div {
    padding: 0;
}

@media only screen and (max-width: 1200px) {
    .news-img {
        padding-bottom: 60%;
    }

    .news-img img {
        width: 120%;
        max-width: 120%;
    }

    .news-content p {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .news-img {
        padding-bottom: 45%;
    }

    .news-img img {
        width: 100%;
        max-width: 100%;
    }

    .index-title h2,
    .index-title h1 {
        font-size: 20px;
    }

    .index-title h2:after,
    .index-title h1:after {
        width: 180px;
    }

    .index-title h3 {
        font-size: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .news-block {
        margin: 10px;
    }

    .news-block .row > div:nth-child(1) {
        width: 35%;
    }

    .news-img {
        padding-bottom: 100%;
    }

    .xs-l-margin {
        margin-left: 15px;
    }

    .news-img img {
        width: 100%;
        max-width: 100%;
        /*margin-left: -80%;*/
    }

    .news-block-w {
        width: 100%;
    }

    .news-block .row > div:nth-child(2) {
        width: 65%;
    }

    .news-content p {
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 420px) {
    .news-content {
        padding-left: 10px;
    }

    .news-content p {
        margin-bottom: 3px;
        line-height: 22px;
    }

    .news-content .news-title {
        max-height: 44px;
    }
}


/*-----------------------
    15.3 index > change slider
-------------------------*/
div.testimonials {
    padding-top: 30px;
}

.bg-gray {
    background: #f6f6f6;
    padding-top: 30px;
}

.slider-captions {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.slider-captions .text-title {
    font-size: 20px;
    color: #665c5b;
    border-bottom: 1px solid #fff;
    position: relative;
    margin-bottom: 20px;
    display: inline-block;
    font-weight: 400;
}

.slider-title {
    font-size: 30px;
    font-weight: 400;
    color: #665c5b;
    letter-spacing: -2px;
    text-transform: capitalize
}

.slider-text {
    font-size: 16px;
    margin-bottom: 20px;
    color: #665c5b;
    font-weight: 400;
}

.owl-change .btn-primary {
    color: #7d7d7d;
    background-color: transparent;
    border: 1px solid #7d7d7d;
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 20px;
}

.owl-change .btn-primary:hover {
    color: #3166ad;
    border: 1px solid #3166ad;
}

.owl-change .owl-stage-outer {
    background-color: #EEF1FA;
    /*background: url('/images/slider/change.jpg') 0 0 no-repeat;*/
    background-size: cover;
}

.owl-change .slider-captions-outer {
    position: relative;
}

.owl-carousel .owl-stage {
    -webkit-transition: all 0s ease 0s !important;
    transition: all 0s ease 0s !important;
}


@media only screen and (max-width: 1600px) {
    .slider-captions .text-title, .slider-text {
        margin-bottom: 10px;
    }

    .owl-change .btn-primary {
        margin-top: 10px;
    }

}

@media only screen and (max-width: 1200px) {
    .owl-carousel.owl-change .owl-item img {
        width: 160% !important;
        margin-left: -30%;
    }
}

@media only screen and (max-width: 767px) {
    .owl-carousel.owl-change .owl-item img {
        width: 200% !important;
        margin-left: -50%;
    }

    .slider-text {
        margin-bottom: 5px;
    }

    .owl-change .btn-primary {
        font-size: 14px;
        border-radius: 20px;
        padding: 4px 10px;
    }

    .owl-change .btn-primary {
        margin-top: 20px;
    }
}

/*-----------------------
    15.4 index > testimonials
-------------------------*/
.testimonials-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 24% 24% 24% 24%;
    color: #444;
}

.img-box {
    /*border-radius: 5px;*/
    line-height: 0;
    padding-bottom: 80%;
    position: relative;
    overflow: hidden;
}

@media only screen and (min-width: 768px) {
    .img-a {
        grid-column: 1;
        grid-row: 1;
    }

    .img-b {
        grid-column: 2;
        grid-row: 1;
    }

    .img-c {
        grid-column: 3;
        grid-row: 1;
    }

    .img-d {
        grid-column: 4;
        grid-row: 1 / span 2;
    }

    .img-e {
        grid-column: 1 / span 2;
        grid-row: 2;
        padding-bottom: 40%;
    }

    .img-f {
        grid-column: 3;
        grid-row: 2;
    }
}

.img-box a {
    display: block;
    overflow: hidden;
    vertical-align: top;
    width: 100%;
    height: 100%;
}

.img-box .img-box-1 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    position: absolute;
}

.img-box .img-box-text {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
    width: 100%;
    padding: 8px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 2;

    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -o-transform: translateY(50px);
    -ms-transform: translateY(50px);
}

.img-box a:hover .img-box-text {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.testimonials .btn-primary {
    color: #3166ad;
    background-color: transparent;
    border: 1px solid #3166ad;
    border-radius: 20px;
    padding: 8px 25px;
    margin: 30px auto 0;
}

.testimonials .btn-primary:hover {
    color: #fff;
    background: #1e2d57;
    border: 1px solid #1e2d57;
}

@media only screen and (max-width: 767px) {
    .testimonials-wrapper {
        grid-template-columns: 49% 49%;
        padding: 0 15px;
    }

    .img-box {
        padding-bottom: 60%;
    }
}

@media only screen and (max-width: 480px) {
    .testimonials-wrapper {
        grid-template-columns: 100%;
        padding: 0 15px;
    }

    .img-box {
        padding-bottom: 45%;
    }

    .img-box .img-box-text {
        opacity: 1;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        -ms-transform: none;
        padding: 5px 20px;
    }
}

/*-----------------------
    15.5 index > video
-------------------------*/
.index-video {
    margin: 30px 0 0;
    padding: 30px 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-image: url('/images/slider/video.jpg');
}

.slick-slide {
    height: auto;
}

.slick-slide img {
    width: 100%;
}

.slick-prev:before, .slick-next:before {
    color: black;
}

.slick-slide {
    transition: all ease-in-out .3s;
    opacity: .2;
}

.slick-active {
    opacity: .5;
}

.slick-current {
    opacity: 1;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-prev::before, .slick-next::before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.video-slick-center {
    width: 100%;
}

.slick-slide:not(.slick-center) {
    z-index: 0;
}

.video-slick-center .slick-slide {
    position: relative;
    z-index: 1;
    margin: 0 -100px;
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
}

.video-slick-center .slick-slide.slick-center {
    z-index: 2;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.video-slick-center .iframe-container {
    display: block;
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
}

.video-slick-center iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-slick-center .slick-prev::before {
    content: "\f104";
}

.video-slick-center .slick-next::before {
    content: "\f105";
}

.video-slick-center .slick-prev, .video-slick-center .slick-next {
    color: #2561a8;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    background: #fff;
    opacity: .6;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.video-slick-center .slick-prev:hover, .video-slick-center .slick-next:hover {
    background: #3166ad;
    opacity: 0.7;
}

.video-slick-center .slick-prev::before, .video-slick-center .slick-next::before {
    font-size: 36px;
    line-height: 50px;
    cursor: pointer;
    display: inline-block;
    font-family: FontAwesome;
}

.video-slick-center .ytp-large-play-button.ytp-touch-device .ytp-large-play-button-bg, .ytp-cued-thumbnail-overlay:hover .ytp-large-play-button- {
    fill: #3166ad;
}

.video-slick-center .slick-dots li {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
}

.video-slick-center .slick-dots li button {
    width: 100%;
    height: 100%;
    margin: 5px;
    -webkit-backface-visibility: visible;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.video-slick-center .slick-dots li button::before {
    content: '';
    width: 8px;
    height: 8px;
    margin: 5px;
    background: transparent;
    border: 1px solid #3166ad;
    display: block;
    border-radius: 50%;
    opacity: 1;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.video-slick-center .slick-active {
    opacity: 1;
}

.video-slick-center .slick-dots li.slick-active button::before, .video-slick-center .slick-dots li.slick button:hover::before {
    background: #3166ad;
    opacity: 1;
}


@media only screen and (min-width: 1200px) {
    .video-slick-center .slick-prev {
        left: -7%;
    }

    .video-slick-center .slick-next {
        right: -7%;
    }
}

@media only screen and (max-width: 1200px) {
    .video-slick-center .slick-prev {
        left: -2%;
    }

    .video-slick-center .slick-next {
        right: -2%;
    }
}

@media only screen and (max-width: 480px) {
    .video-slick-center .slick-slide {
        margin: 0;
    }
}


/*-----------------------
    15.6 index > contact
-------------------------*/
.img-contact {
    max-width: 578px;
    margin-top: 40px;
    overflow: hidden;
    padding: 7px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.contact-info-index h2 {
    font-weight: normal;
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #555;
    line-height: 1.5;
}

.contact-info-index .btn-primary {
    color: #3166ad;
    background-color: transparent;
    border: 1px solid #3166ad;
    border-radius: 20px;
    padding: 8px 15px;
    margin-right: 10px;
    float: right;
}

.contact-info-index .btn-primary:hover {
    color: #fff;
    border: 1px solid #fff;
    background-color: #3166ad;
}

@media only screen and (max-width: 767px) {
    .contact-info-index h2 {
        font-size: 20px;
    }

    .contact-info-index {
        padding: 20px;
    }
}


/*------------------------
  15.7. Floating sns bar
--------------------------*/
.floating-bar {
    position: fixed;
    top: 30%;
    right: 30px;
    z-index: 10;
}

.floating-bar ul {
    width: 40px;
    height: auto;
    padding: 15px 0;
    border-radius: 25px;
    background: #2463a7;
    opacity: 0.85;
}

.floating-bar ul li {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
}

.floating-bar ul li a {
    color: #fff;
    transition: none;
    display: block;
}

.floating-bar ul li a:hover {
    color: #1e2d57;
}

.float-icon-line span, .float-icon-pixnet span {
    display: block;
    width: 100%;
    height: 100%;
    background: url('/images/icon/float-icon.png') 0 0 no-repeat;
    text-indent: -9999px;
}

.float-icon-pixnet span {
    background-position: 0 -40px;
}

.float-icon-line:hover span {
    background-position: -40px 0;
}

.float-icon-pixnet:hover span {
    background-position: -40px -40px;
}


.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clear-fix:after {
    content: "";
    clear: both;
    display: block;
}


/*------------------------
  15.8. m-fixed-bar
--------------------------*/
.m-fixed-bar {
    display: none;
}

.branch-block {
    margin: 15px 0;
    border: 1px solid #e0e0e0;
}
.branch-block h3 {
    color: #6c676f;
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0 0 10px;
    position: relative;
    margin: 0;
}
.branch-block p {
    font-size: 16px;
    padding: 10px 0 10px 10px;
}
.branch-block a.abtn {
    font-size: 14px;
    color: #fff;
    background: rgb(26, 52, 86);
    margin: 20px -2px 0;
    padding: 7px 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.branch-block a.abtn:hover {
    background: #2463a7;
}
.branch-block a.abtn:after{
    content: '\f101';
    font-family: FontAwesome;
    font-size: inherit;
    padding-left: 8px;
}

.branch-info h3 {
    display: inline-block;
    font-size: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid #7d4c9e;
}
.branch-info p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 10px;
}


@media only screen and (max-width: 480px) {
    .branch-block a.abtn {
        margin: 20px 0 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .m-fixed-bar {
        display: block;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .m-fixed-bar ul {
        width: 100%;
        height: auto;
    }

    .m-fixed-bar ul li {
        display: inline-block;
        float: left;
        width: 50%;
        height: 45px;
        text-align: center;
        line-height: 45px;
        font-size: 16px;
        background-color: #1a3456;
    }

    .m-fixed-bar ul li a {
        color: #f2f2f2;
        display: block;
    }

    .m-fixed-bar ul li .m-fixed-bar-line {
        background-color: #3166a7;
    }

    .m-fixed-bar .fa-phone {
        font-size: 20px;
    }

    .cd-top {
        bottom: 55px;
    }

    .footer {
        padding-bottom: 45px;
    }

}


/*------------------------
  16. Service
--------------------------*/
.service-page > .row {
    margin-bottom: 30px;
}

.service-page > .row:first-child {
    margin-bottom: 30px;
}

.case-page > .row:first-child {
    text-align: center;
}

.service-page h1, .case-page h1 {
    text-align: center;
    font-size: 34px;
    line-height: 1.3;
    font-weight: 800;
    color: #2463a7;
    background: -webkit-linear-gradient(#2463a7, #1e2d57);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@supports (-webkit-background-clip: text) {
    .service-page h1, .case-page h1 {
        background: -webkit-linear-gradient(#2463a7, #1e2d57);
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .case-page h1 {
        display: inline;
        margin: 0 auto;
    }
}

.service-page h2 {
    margin: 10px;
    color: #3166ad;
    font-size: 26px;
    padding-left: 30px;
    background: url('/images/icon/service-h2.png') 0 2px no-repeat;
}

.service-page p {
    margin: 15px;
    color: #4d4d4d;
    line-height: 2;
    text-align: justify;
}

.service-page hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border-top: 1px solid #ddd;
}

.service-page .justify {
    text-align: justify;
}

.service-page .text-center {
    text-align: center;
}

.service-page .suitable {
    background: #eee;
    padding: 25px 15px;
    border-radius: 10px;
    margin: 0 0 30px 0;
}

.service-page .suitable h3 {
    color: #3166a7;
    font-size: 26px;
    padding-left: 10px;
    margin-left: 7px;
    border-left: 3px solid #3166a7;
}

.service-page .suitable p {
    margin: 5px;
}

.service-page .suitable li {
    margin-bottom: 8px;
}

.contact-form label {
    line-height: 36px;
    text-align: right;
    padding-right: 5px;
    padding-left: 5px;
}

.contact-form .btn-primary {
    color: #7d7d7d;
    background-color: #fff;
    border: 1px solid #7d7d7d;
    border-radius: 20px;
    padding: 8px 25px;
    margin: 30px auto 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.contact-form .btn-primary:hover {
    color: #fff;
    background-color: #3166ad;
    border: 1px solid #3166ad;
}

.contact-form input:focus, .contact-form textarea:focus, .contact-form select:focus {
    box-shadow: none;
    border: 1px solid #2463a7;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(36, 99, 167, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(36, 99, 167, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(36, 99, 167, 0.5);
}

#rand-img {
    margin: 0 3px 0 10px;
}


@media only screen and (min-width: 1200px) {
    .service-page h2 {
        margin-left: -18px;
    }
}

@media only screen and (max-width: 992px) {
    .service-page .suitable {
        margin: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .service-page > .row {
        margin-bottom: 0;
    }

    .service-page > .row:first-child {
        margin-bottom: 0;
    }

    .service-page .img-responsive {
        margin: 10px auto;
    }

    .service-page h1 {
        font-size: 26px;
        margin: 0 20px;
    }

    .service-page h2 {
        font-size: 22px;
        margin-top: 25px;
    }

    .service-page hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .contact-form label {
        padding-left: 15px;
    }

    .service-page .suitable {
        margin: 0 0 25px 0;
        padding: 25px 5px;
    }

    .service-page .suitable ol {
        margin-left: -10px;
    }

    .index-title.service-contact {
        margin-bottom: 10px;
    }

    .contact-form {
        padding: 0 30px 10px;
    }

    .contact-form label {
        line-height: 24px;
    }

    .contact-form .form-group {
        margin-bottom: 20px;
    }

    .contact-form-radio {
        margin-top: -20px;
    }

    .contact-form-radio .form-group {
        margin-bottom: 15px;
    }
}


/*------------------------
  16.1 Service > table
--------------------------*/
.table.service-table > tbody > tr > td, .table.service-table > tbody > tr > th {
    line-height: 1.8;
}

.service-table td, .service-table th, .service-table thead th {
    border-color: #ccc;
}

.service-table th {
    font-size: 17px;
    text-align: center;
    color: #fff;
    background-color: #3166a7;
}

.service-table thead th {
    font-size: 22px;
    background-color: #003764;
}

.service-table td {
    color: #555;
    background-color: #f9f9f9;
}

.service-table tr:nth-of-type(even) td {
    background-color: #ededed;
}

.service-table img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.service-table.table > tbody > tr > td, .service-table.table > tbody > tr > th, .service-table.table > tfoot > tr > td, .service-table.table > tfoot > tr > th, .service-table.table > thead > tr > td, .service-table.table > thead > tr > th {
    padding: 12px;
}

.service-table.table > tbody > tr > th {
    vertical-align: middle;
}


.service-table.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(250, 110, 160, .05)
}

@media only screen and (max-width: 768px) {
    .service-table {
        width: 95%;
        margin: 10px auto;
    }
}

@media only screen and (max-width: 480px) {
    .service-table th, .service-table td {
        font-size: 15px;
    }

    .service-table thead th {
        font-size: 18px;
    }

    .service-table.table > tbody > tr > td, .service-table.table > tbody > tr > th, .service-table.table > tfoot > tr > td, .service-table.table > tfoot > tr > th, .service-table.table > thead > tr > td, .service-table.table > thead > tr > th {
        padding: 8px;
    }

}


/*------------------------
  17. News
--------------------------*/
.inner-title h2 {
    font-size: 30px;
    color: #1a3456;
    margin-bottom: 0;
}

@supports (-webkit-background-clip: text) {
    .inner-title h2 {
        display: block;
        word-break: break-all;
        background: -webkit-linear-gradient(#2463a7, #1e2d57);
        -webkit-background-clip: text;
        color: transparent !important;
    }
}

.inner-title h2:after, .case-page h1:after {
    content: '';
    display: block;
    width: 250px;
    height: 1px;
    margin: 5px auto;
    background: -moz-linear-gradient(left, rgba(184, 162, 150, 0) 0%, rgb(30, 45, 87) 50%, rgba(184, 162, 150, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(184, 162, 150, 0) 0%, rgb(30, 45, 87) 50%, rgba(184, 162, 150, 0) 100%);
    background: linear-gradient(to right, rgba(184, 162, 150, 0) 0%, rgb(30, 45, 87) 50%, rgba(184, 162, 150, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b8a296', endColorstr='#00b8a296', GradientType=1);
}

.case-page h1:after {
    margin: 5px auto 30px;
}

.inner-title h3 {
    font-size: 18px;
    line-height: 22px;
    font-style: italic;
    font-weight: normal;
}

.inner-title h4 {
    font-size: 26px;
    line-height: 1.5;
}
.inner-title h4 span {
    font-size: 60%;
    letter-spacing: initial;
}

@supports (-webkit-background-clip: text) {
    .inner-title h3,
    .inner-title h4 {
        background: -webkit-linear-gradient(#2463a7, #1e2d57);
        -webkit-background-clip: text;
        color: transparent !important;
    }
}

.newsFilter {
    margin-bottom: 30px;
    text-align: center;
    background: rgb(249, 249, 249);
    background: -moz-linear-gradient(top, rgba(249, 249, 249, 1) 0%, rgba(219, 219, 219, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(249, 249, 249, 1) 0%, rgba(219, 219, 219, 1) 100%);
    background: linear-gradient(to bottom, rgba(249, 249, 249, 1) 0%, rgba(219, 219, 219, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#dbdbdb', GradientType=0);
}

.newsFilter ul {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
}

.newsFilter ul li {
    display: inline-block;
}

.newsFilter ul li a {
    padding: 10px 20px;
    display: block;
    color: #4d4d4d;
    font-size: 17px;
    letter-spacing: 1px;
}

.newsFilter ul li a.current {
    color: #f8f8f8;
    background: #2463a7;
    background: -moz-linear-gradient(top, #2463a7 0%, #1e2d57 100%);
    background: -webkit-linear-gradient(top, #2463a7 0%, #1e2d57 100%);
    background: linear-gradient(to bottom, #2463a7 0%, #1e2d57 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8e61b8', endColorstr='#694184', GradientType=0);
}

.newsFilter ul li a:hover {
    color: #f8f8f8;
    background: rgb(153, 153, 153);
    background: -moz-linear-gradient(top, rgba(153, 153, 153, 1) 0%, rgba(109, 109, 109, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(153, 153, 153, 1) 0%, rgba(109, 109, 109, 1) 100%);
    background: linear-gradient(to bottom, rgba(153, 153, 153, 1) 0%, rgba(109, 109, 109, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#999999', endColorstr='#6d6d6d', GradientType=0);
}

.news-block-inner .news-block {
    margin: 15px 0;
    border: 1px solid #e0e0e0;
}

.news-block-inner .news-block:hover {
    border: 1px solid #3166ad;
    background: #fafafa;
}

.news-block-inner .news-date, .news-block-inner .news-cate {
    display: inline-block;
    font-size: 15px;
}

.news-block-inner .news-cate {
    text-align: right;
    color: #888;
}

.news-block-inner .news-content {
    padding: 10px 30px;
}

.news-block-inner .btn-primary, .treatment-block .btn-primary {
    color: #7d7d7d;
    background-color: #fff;
    border: 1px solid #7d7d7d;
    border-radius: 20px;
    padding: 8px 15px;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 15px;
    float: right;
}

.news-block-inner .btn-primary:hover, .treatment-block .btn-primary:hover {
    color: #3166ad;
    border: 1px solid #3166ad;
}

.news-block1 {
    padding: 20px 10px 0;
    text-align: justify;
}

.post-title {
    border-bottom: 1px solid #d6d2de;
}

.post-block .news-date {
    font-size: 18px;
    color: #888;
    margin-bottom: 10px;
}

.post-block h1 {
    font-size: 24px;
    margin-bottom: 15px;
}

.post-block .service-page h1 {
    font-size: 34px;
}

/*.post-block img {
    margin-top: 30px;
    margin-bottom: 30px;
}
*/
.widget-title {
    color: #3166ad;
    border-bottom: 1px solid #b793d4;
    padding-bottom: 5px;
}

.widget-category {
    padding: 25px;
}

.widget-category .widget-title {
    margin-bottom: 20px;
}

.widget-category ul li {
    margin-bottom: 8px;
    line-height: 24px;
}

.widget-category ul li a {
    font-size: 16px;
    font-weight: 400;
    padding: 7px 10px;
    display: block;
}

.widget-category ul li a:hover {
    background: #fbfbfb;
}

@media only screen and (max-width: 767px) {
    .news-block1 {
        padding-top: 0;
    }

    .widget-category ul li {
        margin-bottom: 0;
    }

    .treatment-block.mt60 {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .post-block {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 550px) {
    .inner-title h2 {
        font-size: 30px;
    }

    .inner-title h3 {
        font-size: 18px;
    }

    .inner-title {
        margin-bottom: 15px;
    }

    .newsFilter {
        background: transparent;
    }

    .newsFilter ul {
        background: transparent;
    }

    .newsFilter ul li {
        width: 45%;
        border: 1px solid #cecece;
        margin: 5px;
    }

    .news-block-inner {
        width: 100%;
    }

    .news-block-inner .news-block {
        border-left: 1px solid #e4e4e4;
    }

    .news-block-inner .btn-primary {
        margin-bottom: 15px;
    }

    .news-block-inner .row > div:nth-child(2) {

        width: 100%;

    }

    .news-block-inner .news-img {
        padding-bottom: 52%;
    }
}


/*------------------------
  18. Testimonials
--------------------------*/
.treatment-block .btn-primary {
    float: none;
}

.treatment-block .treatment-img {
    position: relative;
}

.treatment-block .treatment-img .treatment-mask {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 15px;
    left: 15px;
    background-color: #b4cae7;
    z-index: -1;
}

.treatment-block:nth-child(even) .treatment-img .treatment-mask {
    left: auto;
    right: 15px;
    background-color: #b4cae7;
}


.selfy-title h3 {
    font-size: 22px;
    color: #4d4d4d;
    padding-bottom: 10px;
}

.selfy-title h3:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin: 5px auto;
    background: -moz-linear-gradient(left, rgba(184, 162, 150, 1) 50%, rgba(184, 162, 150, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(184, 162, 150, 1) 50%, rgba(184, 162, 150, 0) 100%);
    background: linear-gradient(to right, rgba(184, 162, 150, 1) 50%, rgba(184, 162, 150, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b8a296', endColorstr='#00b8a296', GradientType=1);
}

.selfy-container {
    text-align: justify;
}

.selfy-container img {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.selfy-container p {
    margin-top: 10px;
}


@media only screen and (min-width: 768px) {
    .treatment-block:nth-child(even) > .row > div:nth-child(1) {
        left: 50%;
    }

    .treatment-block:nth-child(even) > .row > div:nth-child(2) {
        right: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .treatment-img {
        margin-bottom: 15px;
    }

    .treatment-content {
        padding: 20px;
        padding-bottom: 0;
    }

    .treatment-content h2 {
        margin-bottom: 15px;
    }

    .selfy-title h3 {
        font-size: 18px;
    }

    .selfy-container img {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}


/*------------------------
  19. Case
--------------------------*/
.case-page h1 {
    font-size: 30px;
    margin-bottom: 40px;
}

.case-page h3 {
    font-size: 20px;
}

.case-block {
    margin-bottom: 30px;
}

.case-block > div {
    padding: 20px 5px 0;
    position: relative;
}

.case-block > div:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    background: #f8f6f9;
    top: 0;
    left: 0;
    z-index: -1;
}

.case-img {
    width: 95%;
    margin: 10px auto;
    z-index: 3;
}

.case-before {
    color: #b9b9b9;
}

.case-after {
    color: #3166ad;
}

@media only screen and (max-width: 767px) {
    .case-block {
        margin-bottom: 15px;
    }

    .case-page h1 {
        font-size: 26px;
    }

    .case-page h3 {
        font-size: 17px;
    }
}


/*------------------------
  20. About
--------------------------*/
.about-page-text {
    max-width: 950px;
    margin: 0 auto 10px;
    text-align: justify;
}

.about-page-text h4 {
    line-height: 1.7;
}

.about-text1 {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    width: 90%;
    margin: 40px auto 30px;
    background: -moz-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0.29) 22%, rgba(150, 150, 150, 0.3) 23%, rgba(150, 150, 150, 0.2) 53%, rgba(150, 150, 150, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0.29) 22%, rgba(150, 150, 150, 0.3) 23%, rgba(150, 150, 150, 0.2) 53%, rgba(150, 150, 150, 0) 100%);
    background: linear-gradient(to right, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0.29) 22%, rgba(150, 150, 150, 0.3) 23%, rgba(150, 150, 150, 0.2) 53%, rgba(150, 150, 150, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00969696', endColorstr='#00969696', GradientType=1);
}

.about-page {
    padding-bottom: 70px;
    background: url('/filemanager/userfiles/banner/about-bg.jpg') center bottom no-repeat;
}

@media only screen and (max-width: 767px) {
    .about-page-text {
        padding-right: 25px;
        padding-left: 25px;
    }
}


/*------------------------
  20.1 About > Team
--------------------------*/
.team-page > .row {
    display: flex;
    flex-wrap: wrap;
}

.team-page h1 {
    text-align: center;
    font-weight: normal;
    letter-spacing: 10px;
    position: relative;
}

.team-page h1:before {
    content: '';
    display: block;
    width: 150px;
    height: 2px;
    background: #59575e;
    position: absolute;
    left: 0;
}

.team-block1 {
    margin: 40px 5px 20px 35px;
}

.team-block1 > div {
    background: #59575e;
}

.team-block1:hover > div {
    background: #2463a7;
    background: -moz-linear-gradient(top, #2463a7 0%, #1e2d57 100%);
    background: -webkit-linear-gradient(top, #2463a7 0%, #1e2d57 100%);
    background: linear-gradient(to bottom, #2463a7 0%, #1e2d57 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8e61b8', endColorstr='#694184', GradientType=0);
}

.team-block1 > div img {
    width: 100%;
    max-width: 100%;
    margin-left: -15px;
    margin-top: -15px;
}

.team-block1 .dr-name {
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: #fafafa;
    margin-left: 15px;
}

.team-block1 .dr-name span {
    font-size: 20px;
    margin-left: 10px;
}

.team-block1 .dr-name1 {
    color: #59575e;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
    margin-top: 10px;
}

.dr-profile-img img {
    width: 98%;
    max-width: 98%;
    border: 5px solid #fff;
    border-width: 1%;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(166, 166, 166, 1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(166, 166, 166, 1);
    box-shadow: 0px 3px 5px 0px rgba(166, 166, 166, 1);
}

.dr-profile-text h2 {
    font-size: 32px;
    line-height: 1.3;
    color: #2463a7;
}

@supports (-webkit-background-clip: text) {
    .dr-profile-text h2 {
        background: -webkit-linear-gradient(#2463a7, #1e2d57);
        background-clip: border-box;
        -webkit-background-clip: text;
        color: transparent !important;
    }
}

.dr-profile-text h2 span {
    font-size: 26px;
}

.dr-profile-text h3 {
    font-size: 18px;
    font-weight: normal;
}

.dr-profile-text h3:after {
    content: '';
    display: block;
    width: 250px;
    height: 1px;
    margin-top: 15px;
    margin-bottom: 25px;
    background: -moz-linear-gradient(left, rgba(184, 162, 150, 0) 0%, rgba(184, 162, 150, 1) 50%, rgba(184, 162, 150, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(184, 162, 150, 0) 0%, rgba(184, 162, 150, 1) 50%, rgba(184, 162, 150, 0) 100%);
    background: linear-gradient(to right, rgba(184, 162, 150, 0) 0%, rgba(184, 162, 150, 1) 50%, rgba(184, 162, 150, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b8a296', endColorstr='#00b8a296', GradientType=1);
}

.dr-profile-text h4 {
    color: #3166ad;
    margin-top: 30px;
    font-size: 18px;
}

.team-page-inner {
    margin-top: -40px;
    background: rgb(230, 229, 230);
    background: -moz-linear-gradient(top, rgba(230, 229, 230, 1) 0%, rgba(248, 247, 248, 1) 25%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(230, 229, 230, 1) 0%, rgba(248, 247, 248, 1) 25%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(230, 229, 230, 1) 0%, rgba(248, 247, 248, 1) 25%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e6e5e6', endColorstr='#ffffff', GradientType=0);
}

.team-page-inner p {
    text-align: justify;
}

.dr-profile-text1 h3 {
    color: #3166ad;
    font-size: 26px;
    margin-bottom: 20px;
}

.dr-profile-text1 ul {
    list-style: none;
}

.dr-profile-text1 ul li {
    margin-left: 5px;
    line-height: 30px;
}

.dr-profile-text1 ul li::before {
    content: "";
    background: #b4cae7;
    line-height: 26px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
}

.team-page-inner-black {
    background: #514e52;
}

.team-title-black h2 {
    font-size: 26px;
    color: #fff;
    margin-bottom: 0;
    font-weight: normal;
}

.team-title-black h2:after {
    content: '';
    display: block;
    width: 250px;
    height: 1px;
    margin: 5px auto;
    background: #fff;
}

.team-title-black h3 {
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    font-weight: normal;
}

.owl-carousel-dr .item img {
    display: block;
    width: 100%;
    height: auto;
}

.slider-dr.slick-dotted.slick-slider {
    margin-bottom: 50px;
}

.slick-initialized .slick-slide > div {
    padding-left: 10px;
    padding-right: 10px;
}

.slider-dr .slick-active {
    opacity: 1;
}

.slick-dots li, .slick-dots li button {
    width: 4px;
    height: 4px;
}

.slider-dr .slick-dots li button::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    opacity: 1;
    background-color: #ccc;
    margin-top: 10px;
}

.slider-dr .slick-dots li.slick-active button::before {
    background-color: #ae8927;
    opacity: 1;
}

.other-dr.row {
    text-align: center;
}

.other-dr > div {
    margin-bottom: 20px;
    display: block;
}

.other-dr > div > a > div {
    width: 100%;
    max-width: 100%;
    border: 5px solid #fff;
    border-width: 2%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(189, 189, 189, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(189, 189, 189, 1);
    box-shadow: 0px 0px 5px 0px rgba(189, 189, 189, 1);
    position: relative;
}

.other-dr img {
    width: 100%;
    max-width: 100%;
}

.other-dr p {
    font-size: 22px;
    width: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.45);
    color: #fff;
    text-align: center;
    line-height: 50px;
    position: absolute;
    bottom: 0;
}

.other-dr p span {
    font-size: 16px;
    margin-left: 8px;
}


@media only screen and (max-width: 767px) {
    .team-block1 .dr-name {
        line-height: 40px;
        font-size: 24px;
    }

    .team-block1 .dr-name span {
        font-size: 18px;
        margin-left: 6px;
    }

    .team-block1 .dr-name1 {
        font-size: 18px;
        line-height: 28px;
    }

    .team-page-inner > div > .row > div {
        margin-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .team-title-black h2 {
        font-size: 22px;
    }

    .team-title-black h2:after {
        width: 200px;
    }

    .team-title-black h3 {
        font-size: 16px;
    }

}

@media only screen and (max-width: 480px) {
    .team-page-inner-black > div {
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 450px) {
    .team-page .col-xs-6 {
        width: 100%;
    }

    .other-dr p {
        font-size: 18px;
        line-height: 30px;
    }

    .other-dr p span {
        font-size: 14px;
    }
}


/*------------------------
  21. Contact
--------------------------*/
.contact-ul li {
    margin-left: 65px;
    margin-bottom: 20px;
    line-height: 50px;
    position: relative;
}

.contact-ul li:before {
    position: absolute;
    content: "\f095";
    font-family: FontAwesome;
    display: inline-block;
    width: 50px;
    height: 50px;
    font-size: 26px;
    text-align: center;
    color: #676969;
    background-color: #e2e2e2;
    border-radius: 50%;
    line-height: 53px;
    margin-right: 10px;
    margin-left: -60px;
}

.contact-ul li:nth-child(2):before {
    content: "\f10b";
    font-size: 32px;
}

.contact-ul li:nth-child(3):before {
    content: "\f017";
}

.contact-ul2 li:nth-child(1):before {
    content: "\f1ac";
    font-size: 22px;
}

.contact-ul2 li:nth-child(2):before {
    content: "\f0e0";
    font-size: 22px;
}

.contact-ul2 li:nth-child(3):before {
    content: "\f041";
}

.contact-ul2 li:nth-child(4):before {
    content: "\f0e6";
}

.contact-map {
    margin-bottom: -8px;
}

.contact-map iframe {
    width: 100% !important;
}

.contact-o-block.row {
    display: flex;
    flex-wrap: wrap;
}

.contact-o-block h4 {
    font-size: 18px;
}

.contact1 {
    text-align: center;
    width: 90%;
    max-width: 1000px;
    margin: 10px auto 50px;
}

.contact-page h3 {
    color: #3166ad;
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
}

.contact-page .overseas-trans h3 {
    text-align: left;
    margin-bottom: 5px;
}

.contact-page .overseas-trans h3 span {
    font-size: 17px;
    font-weight: normal;
}

.contact-o-block > div {
    margin-bottom: 40px;
}

.contact-page-gray1 {
    background: #f6f6f6;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: justify;
}

.contact-page-gray1 h4 {
    font-size: 17px;
}

.overseas-flow ul {
    text-align: center;
}

.overseas-flow ul li {
    display: inline-block;
    text-align: center;
    width: 15%;
    height: auto;
    outline: none;
    position: relative;
    padding: 10px 0;
    vertical-align: top;
}

.overseas-flow ul li img {
    display: table;
    display: block;
    width: 100%;
    max-width: 130px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 10px;
}

.overseas-flow ul li span {
    font-size: 16px;
    color: #555;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.overseas-flow ul li:after {
    content: '\f178';
    font-size: 20px;
    font-family: FontAwesome;
    position: absolute;
    color: #3166ad;
    right: -10px;
    top: 35%;
}

.overseas-flow ul li:last-child:after {
    content: none;
}

.overseas-trans > div > div {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin: 5px;
    padding: 25px;
    height: 100%;
}

.overseas-ul1 li:before {
    content: '\f054';
    font-family: FontAwesome;
    color: #3166ad;
    margin-right: 7px;
}

ul.overseas-ul2 {
    list-style-type: circle;
    text-align: left;
}

.overseas-ul2 li {
    display: inline-block;
    margin-right: 15px;
}

.overseas-ul2 li:before {
    content: "•";
    padding-right: 8px;
}

.overseas-trans1 img {
    float: left;
    margin-right: 8px;
}

.overseas-trans1 h4 {
    line-height: 35px;
}

.contact-page-gray2 {
    background: #ececec;
    padding-top: 50px;
    padding-bottom: 50px;
}

.contact-page-gray2 h3 {
    line-height: 1.5;
}

.oversea-map iframe {
    width: 100%;
}

.oversea-map-ul {
    margin: 40px 20px;
}

.oversea-map-ul li {
    line-height: 30px;
    margin-bottom: 20px;
}

.oversea-map-ul li a {
    color: #6c676f;
}

.oversea-map-ul li img {
    margin-right: 10px;
}


@media only screen and (min-width: 768px) {
    .row.overseas-trans {
        display: flex;
    }

    .contact-page-gray2 .contact-info {
        margin-top: 70px;
    }

    .oversea-map {
        padding: 0;
    }
}

@media only screen and (max-width: 767px) {
    .overseas-flow ul li {
        padding: 0;
        width: 25%;
        margin: 2%;
    }

    .overseas-flow ul li:after {
        right: -15px;
        top: 25%;
    }

    .row.overseas-3col {
        margin-bottom: 20px;
    }

    .overseas-3col > div {
        margin-bottom: 20px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .overseas-trans > div {
        margin-bottom: 20px;
    }

    .oversea-map-ul li {
        text-align: center;
    }

    .contact-page-gray1 {
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 550px) {
    .contact1 h3 {
        font-size: 20px;
    }

    /*.contact-ul {
    margin-left: 20px;
    margin-right: 20px;
}*/
    .contact-ul:first-child {
        margin-top: 25px;
    }

    /*.contact-o-block > div:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -15px;
    left: 10%;
    width: 80%;
    height: 0;
    margin: 20px auto 0;
    border-bottom: 1px solid #ddd;
}*/
}


/*------------------------
  22. Search Result
--------------------------*/
.result-page {
    min-height: 335px;
}

.search-result-h2 {
    padding-bottom: 8px;
    border-bottom: 1px solid #bebebe;
}

.search-result-h2 span {
    color: #3166ad;
}


/*------------------------
  14. Media Query
--------------------------*/

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
    .slider-title {
        font-size: 20px;
        margin-bottom: 0px;
        letter-spacing: 1px;
        line-height: 20px;
    }

    .slider .owl-theme .owl-nav [class*='owl-'] {
        padding: 0px 7px;
    }

    .slider-text {
        margin-bottom: 10px;
    }

    .team-content {
        position: relative;
        right: 0px;
        bottom: 0px;
        width: 100%;
    }

    .map {
        margin-bottom: 20px;
    }
}


    /* Extra Small Devices, Phones */
    @media only screen and (max-width: 480px) {
        .slider-title {
            font-size: 15px;
            margin-bottom: -10px;
            letter-spacing: 1px;
            line-height: 20px;
        }

        .slider .owl-theme .owl-dots {
            display: none;
        }

        .slider .owl-theme .owl-nav [class*='owl-'] {
            padding: 0px 7px;
        }

        .team-content {
            position: relative;
            right: 0px;
            bottom: 0px;
            width: 100%;
        }

        .page-section {
            padding: 70px 0px 70px 0px;
        }

        .page-title {
            font-size: 20px;
        }

        /*.page-breadcrumb .breadcrumb {font-size: 10px;}*/
        .doctor-social li {
            margin-bottom: 5px;
        }

        .childern {
            padding-left: 0px;
        }

        .error-no {
            font-size: 90px;
        }

        .map {
            margin-bottom: 20px;
        }


    }

    /* Custom, iPhone Retina */
    @media only screen and (max-width: 320px) {
        .slider-title {
            font-size: 15px;
            margin-bottom: 10px;
            letter-spacing: 1px;
            line-height: 20px;
        }

        .slider-captions {
            position: absolute;
            bottom: 20px;
            left: 12%;
            width: 80%;
        }

        .slider .owl-theme .owl-dots {
            display: none;
        }

        .slider .owl-theme .owl-nav [class*='owl-'] {
            padding: 0px 7px;
        }

        .team-content {
            position: relative;
            right: 0px;
            bottom: 0px;
            width: 100%;
        }

        .page-section {
            padding: 70px 0px 70px 0px;
        }

        .page-title {
            font-size: 20px;
        }

        .page-breadcrumb .breadcrumb {
            font-size: 10px;
        }

        .doctor-social li {
            margin-bottom: 5px;
        }

        .childern {
            padding-left: 0px;
        }

        .error-no {
            font-size: 80px;
        }

        .error-icon {
            font-size: 200px;
        }

        .meta span {
            margin-right: 30px;
        }

        .map {
            margin-bottom: 20px;
        }
    }




