@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?88355541');
  src: url('../fonts/fontello.eot?88355541#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?88355541') format('woff2'),
       url('../fonts/fontello.woff?88355541') format('woff'),
       url('../fonts/fontello.ttf?88355541') format('truetype'),
       url('../fonts/fontello.svg?88355541#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?88355541#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-incoming-call:before { content: '\e800'; } /* '' */
.icon-ipad:before { content: '\e802'; } /* '' */
.icon-ipad-contact:before { content: '\e803'; } /* '' */
.icon-ipad-with-message:before { content: '\e804'; } /* '' */
.icon-journalist:before { content: '\e806'; } /* '' */
.icon-link:before { content: '\e80a'; } /* '' */
.icon-list:before { content: '\e80b'; } /* '' */
.icon-magazine:before { content: '\e80c'; } /* '' */
.icon-mouse:before { content: '\e812'; } /* '' */
.icon-news:before { content: '\e814'; } /* '' */
.icon-newspaper:before { content: '\e816'; } /* '' */
.icon-newspaper-1:before { content: '\e817'; } /* '' */
.icon-news-van:before { content: '\e818'; } /* '' */
.icon-open-magazine:before { content: '\e819'; } /* '' */
.icon-phone-book:before { content: '\e81b'; } /* '' */
.icon-phone-connection:before { content: '\e81c'; } /* '' */
.icon-phone-with-message:before { content: '\e81e'; } /* '' */
.icon-position:before { content: '\e820'; } /* '' */
.icon-public-mailbox:before { content: '\e821'; } /* '' */
.icon-search:before { content: '\e826'; } /* '' */
.icon-share:before { content: '\e828'; } /* '' */
.icon-smartphone:before { content: '\e82a'; } /* '' */
.icon-smartphone-with-message:before { content: '\e82b'; } /* '' */
.icon-sms-message:before { content: '\e82c'; } /* '' */
.icon-speaker:before { content: '\e830'; } /* '' */
.icon-speaking:before { content: '\e831'; } /* '' */
.icon-speech-bubble:before { content: '\e832'; } /* '' */
.icon-speech-bubbles:before { content: '\e833'; } /* '' */
.icon-star:before { content: '\e834'; } /* '' */
.icon-tablet-text:before { content: '\e835'; } /* '' */
.icon-telephone:before { content: '\e837'; } /* '' */
.icon-telephone-contact:before { content: '\e838'; } /* '' */
.icon-television:before { content: '\e839'; } /* '' */
.icon-thumb-up:before { content: '\e83a'; } /* '' */
.icon-touch-screen:before { content: '\e83b'; } /* '' */
.icon-upload:before { content: '\e83c'; } /* '' */
.icon-usb-modem:before { content: '\e83d'; } /* '' */
.icon-video-player:before { content: '\e843'; } /* '' */
.icon-wifi:before { content: '\e846'; } /* '' */
.icon-wireless-signal:before { content: '\e847'; } /* '' */
.icon-wireless-signal-1:before { content: '\e848'; } /* '' */
.icon-world-globe:before { content: '\e849'; } /* '' */
.icon-worldwide-shipping:before { content: '\e84a'; } /* '' */
.icon-worldwide-shipping-1:before { content: '\e84b'; } /* '' */
.icon-writing:before { content: '\e84c'; } /* '' */
.icon-add-contact:before { content: '\e84d'; } /* '' */
.icon-arrows:before { content: '\e84f'; } /* '' */
.icon-article:before { content: '\e850'; } /* '' */
.icon-browser:before { content: '\e852'; } /* '' */
.icon-calendar:before { content: '\e853'; } /* '' */
.icon-chat:before { content: '\e855'; } /* '' */
.icon-compass:before { content: '\e856'; } /* '' */
.icon-date:before { content: '\e85a'; } /* '' */
.icon-document:before { content: '\e85c'; } /* '' */
.icon-email:before { content: '\e85f'; } /* '' */
.icon-play-button:before { content: '\e860'; } /* '' */
.icon-eye-(1):before { content: '\e863'; } /* '' */
.icon-eye:before { content: '\e864'; } /* '' */
.icon-first-aid-kit:before { content: '\e865'; } /* '' */
.icon-icon:before { content: '\e866'; } /* '' */
.icon-new-email-outline:before { content: '\e867'; } /* '' */
.icon-two-quotes:before { content: '\e868'; } /* '' */